
 .acscope-close-icon {
    font-size: 20px !important;
 }
 .es-logo-text {
    position: absolute;
    left: -20px;
    width: 70px;
    background: #0b5940;
    border: 3px solid;
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.2);
 }
 /* width */
::-webkit-scrollbar {
  width: 30px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5!important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbdbd!important;
}
@media screen and (max-width: 600px) {
  .es-logo-text {
    top: 34px;
    left: 0;
    width: 50px;
  }
}
@media screen and (min-width: 600px) and (max-width:900px){
    .es-logo-text {
        top: 34px;
        left: 0;
        width: 60px;
    }
    .scope-text{
        margin-left: 50px !important;
    }
}

