
#list_benefits {

  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    padding-bottom: 2em;

    span {
      font-size: 1.2em;
    }
  }
}

