
.select_duration {
    .v-chip {
        .v-avatar {
            margin-right: 0;
        }
        .v-chip__content{
            cursor: pointer;
        }
    }
}
