

.v-window-x-reverse-transition-leave-to {
     transform: translateX(-100%) !important;
}

.v-window-x-reverse-transition-enter {
transform: translateX(100%) !important;
}

.caseStudy {
    background: #EEEEEE;
    width: 100%;

    .caseStudyHeading {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }

    .fontSize18 {
        font-size: 18px;
    }
}

.caseStudyImageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .caseStudyImage {
        width: 100%;
        object-fit: cover;
        position: relative;
        max-height: 400px;
    }

    .imageRight {
        right: 11px;
    }
    .imageRightMobile {
        right: 4px;
    }
}
.flexDirection {
    flex-direction: column;
}
.testimonialBackground {
    width: 100%;
    background: #A0DEBB;
    border-radius: 4px;

    .webWidth {
        max-width: 80%;
        min-width: 80%;
    }

    .mobileWidth {
        max-width: 100%;
        min-width: 100%;
    }

    .interTestimonialDivWrapper {
        position: relative;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 80px;
    }

    .interTestimonialDiv {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        padding: 32px 32px;
        background: #FFFFFF;
        border-radius: 12px;
        margin:auto;
    }

    blockquote.quotes {
        position: relative;

        &:before {
            font-family: Roboto,sans-serif;
            color: #275741;
            position: absolute;
            content: open-quote;
            font-size: 4em;
            margin-top: -0.4em;
        }

        &:after {
            font-family: Roboto,sans-serif;
            color: #275741;
            position: absolute;
            content: close-quote;
            font-size: 4em;
            bottom: 0;
            left: 0;
            margin-bottom: -0.8em;
        }
    }

    .v-window__next, .v-window__prev {
        background: transparent;
    }

    .theme--dark.v-btn.v-btn--icon {
        color: #275741;
    }

    .v-carousel__controls {
        background: none;
    }

    .v-carousel__controls {
        padding-bottom: 20px;
    }
}
.interTestimonialDivWrapper:after {
    background-image: url('../../images/testimonials/polygon.webp');
    content: '';
    background-repeat: no-repeat;
    content: '';
    display: flex;
    position: relative;
    bottom: 6px;
    width: 64px;
    height: 56px;
    border-radius: 4px;
    left: 50px;
    background-size: 100%;
}
.testimonials-container {
    max-width: 1200px;

    .font-weight-regular {
        font-weight: 700 !important
    }

    .oxygen {
        font-family: Oxygen;
    }
    @media (min-width: 641px) {
        h1 {
            font-size: 54px;
            line-height: 68px;
        }
        h2 {
            font-size: 32px;
            line-height: 42px;
        }
    }
}
