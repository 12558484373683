
.v-card {
    width: 300px;
    border-radius: 20px!important;
}
.column-complete {
    z-index: 1;
}
.enterprise-text {
    position: relative
}
