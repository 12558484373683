<template>
    <v-row>

        <v-col cols="5" class="text-center d-none d-lg-block">
            <img :src="this.asset(require('../../images/homepage/stay-connected-mobile-app.png'))" width="382" height="512" alt="Graphic depicting site displayed on a Tablet.">
        </v-col>

        <v-col cols="12" lg="7" class="d-flex align-center">
            <div>

                <h1 class="font-weight-light mb-5 oxygen">Stay Connected on the Go with offline Access through ICC
                    Digital Codes Premium Mobile App</h1>

                <h2 class="primary--text mb-5">
                    Available on <a href="https://apps.apple.com/us/app/digital-codes-premium/id1523315914"
                                    target="_blank" class="cdpBlue--text">App Store</a> or

                    <a href="https://play.google.com/store/apps/details?id=org.iccsafe.codes" target="_blank"
                       class="cdpBlue--text">Google Play</a>
                </h2>

                <p class="mb-5">Access features and content through a native mobile interface while downloading up to 15
                    titles from your
                    Premium subscription for offline capabilities.</p>

                <div>
                    <a href="https://apps.apple.com/us/app/digital-codes-premium/id1523315914" target="_blank">
                        <img :src="this.asset(require('../../images/homepage/download-app-store.png'))" height="64" width="216" alt="Download on the App Store" class="mr-5">
                    </a>

                    <a href="https://play.google.com/store/apps/details?id=org.iccsafe.codes" target="_blank">
                        <img :src="this.asset(require('../../images/homepage/get-on-google-play.png'))" height="64" width="216" alt="Get it on Google Play" class="mr-5">
                    </a>

                </div>

            </div>
        </v-col>

    </v-row>
</template>

<style scoped>
h2 a:hover {
    text-decoration: underline;
}
</style>
