
#top_carousel {
    min-height: 200px !important;
    display: flex;
    align-items: center;
}

#bottom_carousel {
    max-width: 550px;
    margin: 0 auto;
}
