
.peak-ahead {
    border-radius: 5px!important;
    overflow-y: hidden!important;
    .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: unset!important;
    }
    .reference_standard {
        padding: 10px;
    }
}
.peak-ahead-fade {
    position: relative;
}
.peak-ahead-fade::before {
    background-image: linear-gradient( top,
        rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 1 ) 100% );
    background-image: -moz-linear-gradient( top,
        rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 1 ) 100% );
    background-image: -ms-linear-gradient( top,
        rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 1 ) 100% );
    background-image: -o-linear-gradient( top,
        rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 1 ) 100% );
    background-image: -webkit-linear-gradient( top,
        rgba( 255, 255, 255, 0 ) 0%, rgba( 255, 255, 255, 1 ) 100% );
    content: "\00a0";
    height: 10%;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 5;
}
