
.ft-container {

    margin-left: calc(52% - 50vw);
    margin-right: calc(52% - 50vw);

    .ft-img-web {
        height: 360px;
    }

    .ft-img-mob {
        height: auto;
        text-align: center;
    }

    .img {
        top: -43px;
        height: auto;
        width: 100%;
    }

    .gradient {
        background-image: linear-gradient(180deg, #fdf5e6 45.83%, #ffffff 100%);
    }

    .list {
        display: block;
        text-align: center;
    }
    h2 {
        font-size: 28px;
    }
}
