
.dashboard-sharing {
    .v-input--selection-controls__input {
        margin: 10px auto;
    }
    .v-expansion-panel-header--active {
        border-bottom: 1px solid #d6d6d6;
    }
    .sharing-data-iterator {
        width: 100%;
    }
    .sharing-expand {
        max-height: 350px;
        overflow-y: scroll;
    }
    .tab-class {
        max-width: none;
        border: 1px solid #0b5940;
    }
}
