<template>
    <div class="pt-8 pb-4">
        <div>
            <v-row dense>
                <v-col :cols="isMobilePort ? '12' : ''">
                    <h2>Premium Subscriptions</h2>
                </v-col>
                <v-col :cols="isMobilePort ? '12' : ''">
                    <v-btn-toggle
                        v-model="toggleFilter"
                        mandatory
                        :class="isMobilePort ? 'd-flex flex-row' : 'float-right mr-2'">
                    <v-btn
                        outlined
                        color="primary"
                        value="Active"
                        height="40"
                        :class="isMobilePort && 'flex-grow-1'"
                        :disabled="!hasSubscriptions">
                        Active
                    </v-btn>
                    <v-btn
                        outlined
                        color="primary"
                        value="Inactive"
                        height="40"
                        :class="isMobilePort && 'flex-grow-1'"
                        :disabled="!hasSubscriptions">
                        Inactive
                    </v-btn>
                    <v-btn
                        :dark="hasSubscriptions"
                        outlined
                        color="primary"
                        value="All"
                        height="40"
                        :class="isMobilePort && 'flex-grow-1'"
                        :disabled="!hasSubscriptions">
                        All
                    </v-btn>
                    </v-btn-toggle>
                </v-col>
                <v-col :cols="isMobilePort ? 'auto': '3'">
                    <v-select
                        :disabled="!hasSubscriptions"
                        class="d-flex"
                        label="Sort By"
                        v-model="selected"
                        :items="sortBy"
                        :menu-props="{ bottom: true, offsetY: true }"
                        dense
                        outlined
                        flat>
                    </v-select>
                </v-col>
            </v-row>
        </div>
        <v-card outlined>
            <v-data-table
                :headers="headers"
                :items.sync="userSubscriptionsObj"
                class="elevation-1 premium-subscriptions-table">
                <template v-slot:no-data>
                    <p class="pt-4">
                        {{ basicMsg }}
                    </p>
                </template>
                <template #item.Title="{ item }">

                    <span>{{ item.year + ' ' + item.title }}</span>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                    icon
                                    :href="getRoute('current_edition_redirect', {'documentSlug': item.slug})"
                                    target="_blank">
                                <v-icon small v-on="on" v-bind="attrs">
                                    mdi-open-in-new
                                </v-icon>
                            </v-btn>
                        </template>
                        <span class="tooltip-content">Open {{ item.year + ' ' + item.title }} in new tab</span>
                    </v-tooltip>

                </template>
                <template #item.status="{ item }">
                    <v-chip
                        small
                        :color="getChipColor(item)"
                        :text-color="getChipTextColor(item)"
                        :dark="isChipDark(item)"
                    >
                        {{ capitalized(item.sub_status) }}
                    </v-chip>
                </template>
                <template #item.licences="{ item }">
                    <span>
                        {{ item.licences}}
                    </span>
                    <p v-if="isAddRemoveLicenseFeatureEnabled && isLicenseRemoved(item)" class="caption grey--text text--darken-1" :class="isMobilePort && 'text-wrap'">
                        {{ renewalMsg(item) }}
                    </p>
                </template>
                <template #item.BillingFrequency="{ item }">
                    <v-row align="start" no-gutters>
                        <v-col cols="1" align="left" class="mr-3">
                            <v-tooltip bottom v-if="'active' === item.status">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn  icon :class="item.status === 'active' ? '' : 'd-none'">
                                        <v-icon
                                            v-on="on"
                                            v-bind="attrs"
                                            :color="item.auto_renew_date !== '' ? 'green darken-3' : 'accent2'"
                                            >
                                            {{
                                                item.auto_renew_date !== '' ?
                                                'mdi-autorenew' :
                                                'mdi-autorenew-off'
                                            }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span class="tooltip-content">
                                    {{ getSubscriptionMessage(item) }}
                                </span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="10" align="left">
                            <span>
                                {{ billingType(item) }}
                            </span> <br/>
                            <span class="caption grey--text text--darken-1" :class="isMobilePort && 'text-wrap'">
                                {{ billingMsg(item) }}
                            </span>
                            <p
                                v-if="isChangeDurationFeatureEnabled && hasPendingDurationChangeRequest(item)"
                                class="caption grey--text text--darken-1"
                                :class="isMobilePort && 'text-wrap'">
                                {{ billingRenewalMsg(item) }}
                            </p>
                        </v-col>
                    </v-row>
                </template>
                <template #item.actions="{ item }">
                    <template v-if="!isExcludeMemberBenefitCollectionSku(item)">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <div v-on="on" style="display: inline;">
                                    <v-btn
                                        v-on="on"
                                        v-if="isAutoRenewAllowed(item)"
                                        :disabled="!isValidVisaCart"
                                        depressed
                                        color="accent2"
                                        @click="EnableAutoRenew(item)">
                                        Enable Auto-renew
                                    </v-btn>
                                </div>
                            </template>
                            <span v-if="!isValidVisaCart" class="tooltip-content">
                                Add credit card to enable auto-renew
                            </span>
                        </v-tooltip>
                        <v-btn
                            v-if="item.status === 'inactive'"
                            depressed
                            color="accent2"
                            @click="actionsCancelAutoRenew(item)">
                            Resubscribe
                        </v-btn>
                    </template>
                    <v-menu
                        left
                        open-on-click
                        offset-y
                        transition="false"
                    >
                        <template v-slot:activator="{ on }">
                            <v-icon color="black" v-on="on">mdi-dots-vertical</v-icon>
                        </template>
                        <v-list>
                            <v-list-item @click="showActionsOrderDetails(item)">
                                <v-list-item-title>Order Details</v-list-item-title>
                            </v-list-item>
                            <template v-if="isAddRemoveLicenseFeatureEnabled && !isExcludeMemberBenefitCollectionSku(item)">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" style="width: 100%;">
                                            <v-list-item
                                                :class="{ 'disabled-menu-item': !isSubscriptionChangeAllowed(item) }"
                                                @click="isSubscriptionChangeAllowed(item) && showActionsLicenseQuantity(item)"
                                            >
                                                <v-list-item-title>Change License Quantity</v-list-item-title>
                                            </v-list-item>
                                        </div>
                                    </template>
                                    <span v-if="!isSubscriptionChangeAllowed(item)" class="tooltip-content">
                                        Enable auto-renew to change license quantity
                                    </span>
                                </v-tooltip>
                            </template>
                            <template v-if="isChangeDurationFeatureEnabled && !isExcludeMemberBenefitCollectionSku(item)">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <div v-on="on" style="width: 100%;">
                                            <v-list-item
                                                :class="{ 'disabled-menu-item': !isSubscriptionChangeAllowed(item) }"
                                                @click="isSubscriptionChangeAllowed(item) && showActionsBillingFrequency(item)"
                                            >
                                                <v-list-item-title>Change Billing Frequency</v-list-item-title>
                                            </v-list-item>
                                        </div>
                                    </template>
                                    <span v-if="!isSubscriptionChangeAllowed(item)" class="tooltip-content">
                                        Enable auto-renew to change billing frequency
                                    </span>
                                </v-tooltip>
                            </template>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card>
        <v-overlay
            :value="isLoading">
            <v-progress-circular
                :size="50"
                indeterminate
                color="primary">
            </v-progress-circular>
        </v-overlay>
    </div>
</template>


<script>
import Moment from 'moment';
import Mobile from "../inc/mixins/mobile";
import {BillingApi} from '../../api/BillingApi';
import ConfirmDialog from "../ConfirmDialog.vue";
import {AppParameters} from "../../AppParameters";
import { mapGetters } from 'vuex';

export default {
    data: () => ({
        toggleFilter: 'Active',
        sortBy: ['Billing Date', 'Title'],
        selected:'Billing Date',
        userSubscriptions: [],
        headers: [
            {
                text: 'Title',
                align: 'start',
                sortable: false,
                value: 'Title',
                width: '30%'
            },
            { text: 'Status', value: 'status',width: '5%', sortable: false },
            { text: 'Licenses', value: 'licences', width: '10%', sortable: false },
            { text: 'Billing Frequency', value: 'BillingFrequency', width: '25%', sortable: false },
            { text: 'Actions', value: 'actions',align: 'right', sortable: false }
        ],
        dialogVisible: false,
        cancelSubscriptionObj: {},
        isLoading: false,
        basicMsg: 'No subscriptions found',
        loggedId: null,
        logStatus: '',
        logMessage: ''
    }),
    mixins: [Mobile],
    components: {ConfirmDialog},
    props: {
        userPremiumSubscriptions: {
            required: true,
            type: Array
        },
        remoteProfile: {
            required: false,
            type: Object
        },
        userId: {
            type: String,
            required: false
        },
        activateTab: {
            type: String,
            required: false
        }
    },
    computed: {
        ...mapGetters('BillingModule', [
            'getPaymentProfile',
        ]),
        isAutoRenewEnabled(){
            return AppParameters.subscriptions_autorenew_enabled || this.userId;
        },
        isValidVisaCart() {
            return !_.isEmpty(this.getPaymentProfile);
        },
        isAddRemoveLicenseFeatureEnabled() {
            return AppParameters.billing_add_remove_licenses_enabled || this.userId;
        },
        isChangeDurationFeatureEnabled() {
            return AppParameters.billing_change_duration_enabled || this.userId;
        },
        profileName: function() {
            return this.remoteProfile ?
                   this.remoteProfile.name :
                   this.personalProfileName;
        },
        hasSubscriptions() {
            return Boolean(this.userPremiumSubscriptions.length);
        },
        userSubscriptionsObj() {
            this.userSubscriptions = [...this.userPremiumSubscriptions];
            if(this.selected === 'Billing Date') {
                let sortedSubscriptions = this.userSubscriptions.sort( ( a, b) => {
                    return new Date(b.expiration) - new Date(a.expiration);
                });
                // Assign the updated array to the prop using Vue's $set method to notify Vue of the change
                this.$set(this, 'userSubscriptions', sortedSubscriptions);

            } else {
                // Sort by title
                this.userSubscriptions.sort(function(a, b) {
                    if (a.year < b.year) {
                        return 1;
                    } else if (a.year > b.year) {
                        return -1;
                    }
                    if (a.title < b.title) {
                        return -1;
                        } else if (a.title > b.title) {
                            return 1;
                    }
                    return 0;
                });
            }

              // Filter
              if (this.toggleFilter === "Active") {
                return this.userSubscriptions.filter(currentVal => currentVal.status === 'active');
            } else if (this.toggleFilter === "Inactive") {
                return this.userSubscriptions.filter(currentVal => currentVal.status === 'inactive');
            } else if (this.toggleFilter === "All") {
                return this.userSubscriptions;
            }

        }
    },
    methods: {
        isAutoRenewAllowed(item) {
            return (
                this.isAutoRenewEnabled &&
                item.status === "active" &&
                (item.renewal_type === "non-recurring" || (item.sub_status === "cancelled" && item.renewal_type === "recurring"))
            );
        },
        isSubscriptionChangeAllowed(item) {
            return item.status === 'active' && '' !== item.auto_renew_date;
        },
        billingType(item) {
            if('M' === item.subscription_type) {
                return 'Monthly';
            } else if('B' === item.subscription_type) {
                return 'Annual';
            } else {
                return '3-Year';
            }
        },
        billingMsg(item) {

            let { renewal_type, expiration, next_renewal_cost, sub_status, status, refunded_date } = item;
            let usDollar = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD'});

            if (sub_status === "active" && 'non-recurring' === renewal_type) {
                return `Expires on ${Moment(expiration).format('ll')}`;
            } else if (sub_status === "active" && 'recurring' === renewal_type) {
                return `Auto-renews on ${Moment(expiration).format('ll')}`;
            } else if (sub_status === "cancelled") {
                return `Auto-renew cancelled - Expires on ${Moment(expiration).format('ll')}`;
            } else if (sub_status === "expired") {
                return `Expired ${Moment(expiration).format('ll')}`;
            } else if(sub_status === 'refunded') {
                return `User cancelled and refunded ${Moment(refunded_date[0]).format('ll')}`;
            }

        },
        isLicenseRemoved(item){
            return item.next_renewal_license < item.licences;
        },
        renewalMsg(item) {
            return `Changes to ${item.next_renewal_license} on auto-renew`;
        },
        hasPendingDurationChangeRequest(item) {
            return item.next_renewal_subscription_type != item.subscription_type;
        },
        billingRenewalMsg(item) {
            const subscriptionLabels = {
                    'M': 'Monthly',
                    'B': 'Annually'
                };
            const renewalType =  subscriptionLabels[item.next_renewal_subscription_type] || '3-Year';

            return `Changes to ${renewalType} on auto-renew.`;
        },
        getFormattedDate(dateTime){
            return Moment(dateTime).format('ll');
        },
        resetState(){
            this.loggedId = null;
            this.logStatus = '';
            this.logMessage = '';
        },
        openConfirmDialog(orderObj) {
            this.dialogVisible = true;
            this.cancelSubscriptionObj = orderObj;
        },
        showActionsOrderDetails(item) {
            return this.goToTheShop(item.subscription_detail_url, true);
        },
        getOrderDetailsUrl(item) {
            return item.subscription_detail_url;
        },
        showActionsLicenseQuantity(item) {
            item.userId = this.userId;
            EventBus.fire('license-quantity-update-dialog', item);
        },
        showActionsBillingFrequency(item) {
            item.userId = this.userId;
            EventBus.fire('billing-frequency-update-dialog', item);
        },
        displayBillingType(item) {
            const subscriptionLabels = {
                'M': 'Monthly',
                'B': 'Annually'
            };

            return subscriptionLabels[item.subscription_type] || '3-Year';
        },
        actionsCancelAutoRenew(item) {
            this.resetState();
            if (item.status === 'inactive') {
                this.reSubscribe(item);
            }
        },
        reSubscribe(subscription) {
            if (this.userId) {
                return false;
            }
            this.isLoading = true;
            const data = {
                quantity: subscription.licences,
                sku: subscription.sku,
                subscription_type: subscription.subscription_type
            };
            this.$http.get(Routing.generate('create_cart_product', {product: data}))
                .then((resposne) => {
                    if (resposne.ok) {
                        EventBus.fire('set-cart-items-count', {
                            type: 'increment',
                            qty: data.quantity
                        });
                        window.location = Routing.generate('cart_page');
                    }
                    this.isLoading = false;
                }).catch(error => {
                    this.isLoading = false;
                    throw new Error(error);
            });
        },
        notifyUser(data){
            EventBus.fire('notify-cancel-subscription-auto-renew', data);
        },
        capitalized(string) {
            const capitalizedFirst = string[0].toUpperCase();
            const rest = string.slice(1);

            return capitalizedFirst + rest;
        },
        EnableAutoRenew(item){
            EventBus.fire('enable-auto-renew-dialog', item);
        },
        getChipColor(item) {
            if (item.status === "active") {
                if (this.isAutoRenewEnabled && item.sub_status === "cancelled") return "accent2";
                if (!this.isAutoRenewEnabled || item.sub_status === "active") return "green darken-3";
            }

            return item.sub_status === "expired" ? "" : "blue darken-4";
        },
        getChipTextColor(item) {
            if (this.isAutoRenewEnabled && item.status === "active" && item.sub_status === "cancelled") {
                return "black";
            }
            return item.status === "active" ? "white" : "black";
        },
        isChipDark(item) {
            return item.sub_status !== "expired";
        },
        getMemberBenefitCollectionSkus() {
            return AppParameters.member_benefit_collection_skus;
        },
        isExcludeMemberBenefitCollectionSku(item) {
            const excludedSkus = this.getMemberBenefitCollectionSkus();
            if(Array.isArray(excludedSkus) && excludedSkus.length > 0) {
                return excludedSkus.includes(item.next_renewal_sku);
            }
            return false;
        },
        getSubscriptionMessage(item) {
            if (item.auto_renew_date !== '') {
                return "Subscription renews automatically";
            }
            if (!this.isExcludeMemberBenefitCollectionSku(item)) {
                return "Subscription will not renew";
            }
            return "Subscription will renew automatically with active membership status.";
        }
    },
    mounted(){
        if (this.activateTab) {
            this.toggleFilter = this.activateTab
        }
    }
}
</script>
<style scoped>
    .disabled-menu-item {
        pointer-events: none; /* Prevent click events */
        opacity: 0.5; /* Make it look disabled */
    }
</style>
