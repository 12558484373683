
@import '~vuetify/src/styles/settings/_colors.scss';

@mixin icc-alert-color-override($palette, $accentColor, $backgroundColor)  {
    // alert's background color override
    background-color: map-get($palette, $backgroundColor);

    // Alert/s icon color override
    // @XXX: this stile should not target the close button!
    .v-alert__wrapper > .v-icon {
        // the base theme uses `!important`, so we have to use it too ;(
        color: map-get($palette, $accentColor) !important;
        caret-color: map-get($palette, $accentColor) !important;
        align-self: center;
    }

    // Alert's border color override
    .v-alert__border {
        // the base theme uses `!important`, so we have to use it too ;(
        border-color: map-get($palette, $accentColor) !important;;
    }

    // Alert's close button color override
    .v-btn__content > .v-icon {
        color: #000;
    }
}

.icc-alert {
    &.alert-success {
        @include icc-alert-color-override($green, 'darken-3', 'lighten-5');
    }

    &.alert-error {
        @include icc-alert-color-override($red, 'accent-4', 'lighten-5');
    }

    &.alert-warning {
        @include icc-alert-color-override($orange, 'darken-2', 'lighten-5');
    }

    &.alert-info {
        @include icc-alert-color-override($blue, 'darken-4', 'lighten-5');
    }

    .addon-content {
        text-align: left;
        padding-top: 16px;
    }
    .alert-message {
        white-space: normal;
        word-wrap: break-word;
        max-width: 100%;
    }

}
