
.exam-tips-container {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    img {
        max-width: 100%;
    }
    .oxygen {
        font-family: Oxygen;
    }
    .exam-link-detail{
        font-size: 20px;
        line-height: 26px;
        font-weight: 700; 
    }
    @media (min-width: 768px) {
        h1 {
            font-size: 36px;
            line-height: 43px;
        }
        .feature-tips{
            padding: 10px 268px 10px 268px;
        }
    }
    @media (min-width: 768px) and (max-width: 1088px) {
        .feature-tips{
            padding: 10px 100px 10px 100px;
        }
    }
    @media (max-width: 768px) {
        .mobile-scroll {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(2, calc(100% - 50px));
            overflow-x: auto;
        }
    }
}
