<template>
    <div data-qa="content-search" class="grey lighten-5">

        <v-navigation-drawer
            app
            clipped
            width="360px">
            <title-search-filters
                    v-show="isTitleSearch">
            </title-search-filters>
            <content-search-filters
                v-show="isContentSearch"
                class="px-4 mb-4">
            </content-search-filters>
            <graphics-search-filters
                v-show="isGraphicsSearch">
            </graphics-search-filters>
        </v-navigation-drawer>

        <v-tabs
            v-model="activeTab"
            align-tabs="left"
            class="white px-4">
            <v-tab
                class="font-weight-bold"
                :class="activeTab === 0 ? 'white--text' : 'grey--darken-1'">
                <v-icon class="pr-1">
                    mdi-text-box-multiple-outline
                </v-icon>
                TITLES
            </v-tab>
            <v-tab
                class="font-weight-bold px-4"
                :class="activeTab === 1 ? 'white--text' : 'grey--darken-1'">
                <v-icon class="pr-1">
                    {{ isContentSearchTabEnabled ? 'mdi-text-box-search-outline' : 'mdi-lock-outline'}}
                </v-icon>
                CONTENT
            </v-tab>
            <v-tab
                v-if="graphicsSearchEnabled"
                class="font-weight-bold px-4"
                :class="activeTab === 2 ? 'white--text' : 'grey--darken-1'">
                <v-icon class="pr-1">
                    mdi-image-outline
                </v-icon>
                GRAPHICS
            </v-tab>
        </v-tabs>

        <v-divider>
        </v-divider>
        <div
            class="grey lighten-5 px-4 content-container"
            style="min-height: 100vh;">
            <titles-result v-show="isTitleSearch">
            </titles-result>
            <content-search-results v-show="isContentSearch && isContentSearchTabEnabled">
            </content-search-results>
            <dummy-content-results v-if="isContentSearch && !isContentSearchTabEnabled">
            </dummy-content-results>
            <graphics-search-results v-show="isGraphicsSearch">
            </graphics-search-results>
        </div>
    </div>
</template>

<script>
    import ContentSearchResults from "../../components/search/content/ContentSearchResults.vue";
    import DummyContentResults from "../../components/search/content/DummyContentResults.vue";
    import ContentSearchFilters from "../../components/search/content/filters/ContentSearchFilters.vue";
    import TitlesResult from "../../components/search/titles/Results.vue"
    import TitleSearchFilters from "../../components/search/titles/filters/TitleSearchFilters.vue";
    import GraphicsSearchResults from "../../components/search/graphics/GraphicsSearchResults.vue";
    import GraphicsSearchFilters from "../../components/search/graphics/filters/GraphicsSearchFilters.vue";
    import AlertBar from "../../components/notifications/AlertBar.vue";

    import Mobile from "../../components/inc/mixins/mobile";
    import {EventNames} from "../../classes/EventNames";
    import {AppParameters} from "../../AppParameters";

    export default {
        beforeCreate() {
            /**
             * Fetching the tab token from the URL and redirecting to the default tab if the token is invalid.
             */
            const urlPathTokens = window.location.pathname.split('/');

            const tabToken = urlPathTokens.pop();
            const baseUrl = `${window.location.origin}${urlPathTokens.join('/')}`;

            const availableTabs = new Set([
                this.$getConst('titleSearchType'),
                this.$getConst('contentSearchType'),
                this.$getConst('graphicsSearchType')
            ]);

            // redirect to the first tab if the token is not valid
            if (!availableTabs.has(tabToken)) {
                const defaultTab = this.$getConst('titleSearchType');

                window.location.href = `${baseUrl}/${defaultTab}`;
            }

            this.initialTab = tabToken;
            this.baseUrl = baseUrl;
        },
        data() {
            return {
                activeTab: null,
                graphicsSearchEnabled: AppParameters.graphics_search_enabled,
            }
        },
        computed: {
            isTitleSearch() {
                return this.activeTab === 0;
            },
            isContentSearch() {
                return this.activeTab === 1;
            },
            isGraphicsSearch() {
                return this.activeTab === 2;
            },
            isContentSearchTabEnabled() {
                return this.hasPremiumSubscription;
            }
        },
        watch: {
            activeTab(value) {
                let tab
                let eventType
                if (value === 0) {
                    tab = this.$getConst('titleSearchType')
                    eventType = EventNames.INIT_TITLE_SEARCH
                    this.setStoreTab({tab, eventType});
                    this.validateSelectedYears();
                    // Check to see if we need to refresh the title search results.
                    if (this.checkSearchChange(tab)) {
                        EventBus.fire('update-titles-search', this.defaultSearch)
                    }
                } else if (value === 1) {
                    tab = this.$getConst('contentSearchType')
                    eventType = EventNames.INIT_CONTENT_SEARCH
                    this.setStoreTab({tab, eventType})
                    EventBus.fire('update-content-search-results', this.defaultSearch)
                } else if (value === 2) {
                    tab = this.$getConst('graphicsSearchType')
                    eventType = EventNames.INIT_GRAPHICS_SEARCH
                    this.setStoreTab({tab, eventType});
                    this.validateSelectedYears();
                    // Check to see if we need to refresh the graphics search results.
                    if (this.checkSearchChange(tab)) {
                        EventBus.fire(EventNames.UPDATE_GRAPHICS_SEARCH, this.defaultSearch)
                    }
                } else {
                    throw new Error(`Unable to map tab index to tab type: ${value}`);
                }

                if (tab !== this.initialTab) {
                    this.initialTab = tab;

                    /**
                     * Update the URL using history API to prevent page reload
                     *
                     * @TODO: For a perfect UX, we should also update the URL query params to reflect the current search state.
                     */
                    let url = new URL(`${this.baseUrl}/${tab}`);
                    let currentState = {
                        _id: 'tabbed-search',
                        tab: tab,
                    }

                    // handle any params here? and also on filter change somehow ...
                    // url.searchParams.set('q', this.$store.state.searchQuery);
                    window.history.pushState(currentState, '', url);
                }
                // If we have tabbed URL params then we need to update the URL to reflect.
                this.updateBrowserUrl(tab)
            }
        },
        baseUrl: '/search/',
        initialTab: null, // We do not have access to $props in beforeCreate() so this serves as intermediary storage.
        components: {
            GraphicsSearchResults,
            GraphicsSearchFilters,
            TitleSearchFilters,
            AlertBar,
            ContentSearchFilters,
            ContentSearchResults,
            TitlesResult,
            DummyContentResults
        },
        mixins: [Mobile],
        methods: {
            setStoreTab(data) {
                this.$store.commit('setTabbedSearchType', data);
            },
            setTabByType(type) {
                switch (type) {
                    case this.$getConst('titleSearchType'):
                        this.activeTab = 0;
                        break;
                    case this.$getConst('contentSearchType'):
                        this.activeTab = 1;
                        break;
                    case this.$getConst('graphicsSearchType'):
                        this.activeTab = 2;
                        break;
                    default:
                        throw new Error(`Invalid tab: ${type}`);
                }

                this.$store.commit('setTabbedSearchType', this.initialTab);
            },
            checkSearchChange(tab) {
                const hasChanged = this.tabbedSearchValues[tab] !== this.defaultSearch;
                if (hasChanged) {
                    // If search has updated and URL params are present then we need to update the URL params as well.
                    if (this.tabbedSearchUrl[tab]) {
                        this.$store.commit('setTabbedSearchUrl', {
                            tab: tab,
                            searchUrl: this.tabbedSearchUrl[tab],
                            updateSearchTerm: true,
                            removeSid: tab === this.$getConst('contentSearchType')
                        });
                        return true
                    }
                }
                return false
            },
            updateBrowserUrl(tab) {
                const searchParamsString = this.tabbedSearchUrl[tab];

                if (searchParamsString) {
                    const newUrl = `${window.location.pathname}?${searchParamsString}`;
                    window.history.pushState({ path: newUrl }, '', newUrl);
                }
            },
            validateSelectedYears() {
                EventBus.fire('validate-selected-years');
            }
        },
        mounted() {
            this.setTabByType(this.initialTab);
            EventBus.listen('activateSearchTab', (tab) =>{
                this.setTabByType(tab);
            });
        },
        destroyed() {
            this.$store.commit('setTabbedSearchType', null);
        }
    }
</script>
<style scoped>
.v-tab--active {
    background: unset !important;
    color: var(--v-primary-base) !important;
}
</style>

<style lang="scss">
div[data-qa="content-search"] .v-tabs-bar {

  @media (min-width: 1264px) {
    max-width: 1200px;
    margin: 0 auto;
  }
}
</style>