
@import "../../scss/variables.scss";
.system-banner-text {
    a {
        color: $accent-color!important;
        font-weight: 600;
        text-decoration: underline;
    }
    a:hover {
        text-decoration: underline!important;
    }
}
