
.notes-expansion-panel {
    .v-expansion-panel:before {
        box-shadow: unset!important;
    }
    .v-expansion-panel-content__wrap {
        padding: 0;
    }
    .v-expansion-panel-header {
        padding-left: 0 !important;
        padding-right: 0 !important;
        min-height: 40px;
    }
}
