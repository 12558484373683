
.print-menu-content {
    position: absolute;
    border: 1px solid lightgrey;
    background-color: #f9f9f9;
    min-width: 182px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    display: none;
    top:34px;
    right: 0;
    z-index: 10;
    box-shadow: inset -1px 3px 8px 5px #c4d2c312, 2px 5px 16px 0px #0B325E, 5px 5px 15px 5px rgba(0,0,0,0);
    -webkit-box-shadow: inset -1px 3px 8px 5px #c4d2c312, 2px 5px 16px 0px #0B325E, 5px 5px 15px 5px rgba(0,0,0,0);
}
.print-menu-content a:hover {
    background-color: #c9c9c9;
    color: black !important;
}
.print-menu-content a {
    color: black !important;
    padding: 8px;
    text-decoration: none !important;
    display: block;
}
