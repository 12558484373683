
#contentParent{
    section {
        p {
            margin-right: 10px;
        }
        .note-list, p, h1, td {
            -ms-user-select: none;
            user-select: none;
        }
    }
    .section-action-wrapper {
        overflow-x: auto;
        .v-icon:hover {
            cursor: pointer;
        }
    }
    .search_back {
        cursor: pointer;
    }
    .no_highlight {
        background: white !important;
    }
    @media (max-width: 1200px) {
        .section-action-wrapper h1:first-of-type {
            margin-top: 30px !important;
            overflow: hidden;
        }
    }
    .section-action-buttons {
        position: absolute;
        top: 10px;
        right: 5px;
        cursor: pointer;
    }
    .note-action-buttons {
        position: fixed;
        width: 56px;
        height: 40px;
    }
    .note-action-buttons i {
        font-size: 16px;
    }
    .highlighter:hover {
        cursor: pointer;
    }
    .material-icons {
        -ms-user-select: none;
        user-select: none;
    }
    p:hover, h1:hover {
        cursor: text;
    }
    ::selection {
        background: #FFFF00;
    }
    ::-moz-selection {
        background: #FFFF00;
    }
    .trigger-section-action {
        position: absolute;
        right: 0;
    }
    .v-expansion-panel {
        list-style-type: none!important;
        .v-expansion-panel-content__wrap {
            padding: 0;
        }
    }
    .section-action-wrapper {
        .v-icon {
            color: rgba(0,0,0,.65)!important;
        }
        .v-icon--disabled {
            color: rgba(0,0,0,.38)!important;
        }
    }
}
@media print {
    .restrict_print {
        display: none !important;
    }
}
body #app .newIccStyles .deletion_marker {
    width: auto;
    padding: 12px 0px 0px 15px;
}
.migrate-compare {
    section {
        .note-list, p, h1, td {
            -ms-user-select: text !important;
            user-select: text !important;
        }
    }
}
ins {
    display: inline-block;
}
