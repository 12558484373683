#contentParent {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    ul.subsection-listing, ul.subsection-listing ul {
        list-style: none outside none;
        margin: 0;
        padding: 0;
    }

    .center {
        width: 100%;
    }

    .revision-content p, .revision-content ol, .revision-content ul, .revision-content li, .revision-content td, .sectionContent p, .sectionContent ol, .sectionContent ul, .sectionContent li, .sectionContent td, .revision-notes {
        color: #404040;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-weight: normal;
        line-height: 18px !important;
    }

    .sectionTitle {
        margin: 20px 0 0;
        text-align: center;
    }

    .content_bold {
        font-weight: bold !important;
    }

    .ICCSECTION_l1_L4 {
        text-align: center;
        font-family: 'Open Sans';
        font-size: 18px !important;
        font-weight: bold !important;
        margin-top: 5px;
    }

    .book_chapter_part_section {
        text-align: center;
        font-family: 'Open Sans';
        font-size: 18px !important;
        font-weight: bold !important;
        margin-top: 5px;
    }

    .ICCSECTION_l2-L7 {
        text-align: left;
        margin-left: 36pt;
    }

    .ICCSECTION_l3-L8 {
        text-align: left;
        margin-left: 54pt;
    }

    .ICCSECTION_l4_L9 {
        text-align: left;
        margin-left: 72pt;
    }

    .content_italics {
        font-style: italic;
    }

    .sectionContent i > sub, .revision-container i > sub {
        font-style: italic;
    }

    div.print-section a {
        text-align: center;
        color: #818181 !important;
        display: block;
        text-decoration: none !important;
        font-size: 0.8em;
    }

    div.print-section a.disabled {
        display: none;
    }

    .print-link {
        color: #818181;
    }

    .print-link.disabled {
        cursor: text;
        text-decoration: none;
    }

    .table_div table {
        width: 98%;
        text-align: center !important;
    }

    .table_div table td {
        text-align: left;
        padding: 2px;
    }

    .ICCBULLET_L6 {
        margin-left: 36pt;
    }

    .ICCNOTE, .ICCEXCEPTION {
        margin-left: 18pt !important;
    }

    .padding_table_AppB td {
        padding-right: 10px !important;
    }

    .blue {
        color: blue;
    }

    .red {
        color: red;
    }

    .ICCSECONDPARA {
        text-align: justify;
        text-indent: 18pt;
    }

    .ICCSECTION_l3_L6 {
        text-align: justify;
        margin-left: 18pt;
    }

    /* classes for ohio */
    .OHIOSECTION_l1_L4 {
        text-align: center;
        font-family: 'Open Sans';
        font-size: 18px !important;
        font-weight: bold !important;
        margin-top: 5px;
    }

    .OHIOSECTION_l2-L7 {

        text-align: left;
        margin-left: 36pt;
    }

    .OHIOSECTION_l3-L8 {

        text-align: left;
        margin-left: 54pt;
    }

    .OHIOSECTION_l4_L9 {

        text-align: left;
        margin-left: 72pt;
    }

    .OHIOBULLET_L6 {
        margin-left: 36pt;
    }

    .OHIONOTE, .OHIOEXCEPTION {
        margin-left: 18pt !important;
    }

    .OHIOSECONDPARA {
        text-align: justify;
        text-indent: 18pt;
    }

    .OHIOSECTION_l3_L6 {
        text-align: justify;
        margin-left: 18pt;
    }

    .ICCSECTION_l4_L7 {
        margin-left: 36pt;
        text-align: justify;
    }

    .chapter_part {
        font-family: 'Open Sans';
        font-size: 18px !important;
        font-weight: bold !important;
        margin-top: 30px;
    }

    .paragraph_underline {
        text-decoration: underline !important;
    }

    .ICCSECTION_l5_L8 {
        text-align: left;
        margin-left: 68pt;
    }

    /* for florida book */
    .FLCBULLET_L6 {
        margin-left: 36pt;
    }

    .FLCBULLET_L7 {
        margin-left: 40pt;
    }

    .FLCBULLET_L8 {
        margin-left: 44pt;
    }

    .FLCSECTION_l3_L6 {
        text-align: justify;
        margin-left: 18pt;
    }

    .FLCSECTION_l1_L4 {
        text-align: center;
        font-family: 'Open Sans';
        font-size: 18px !important;
        font-weight: bold !important;
        margin-top: 5px;
    }

    .FLCSECTION_l4_L7 {
        margin-left: 36pt;
        text-align: justify;
    }

    .FLCSECTION_l5_L8 {
        text-align: left;
        margin-left: 68pt;
    }

    .FLCSECTION_l2-L7 {
        text-align: left;
        margin-left: 36pt;
    }

    .FLCSECTION_l3-L8 {
        text-align: left;
        margin-left: 54pt;
    }

    .FLCSECTION_l4_L9 {

        text-align: left;
        margin-left: 72pt;
    }

    .FLCBULLET_L6 {
        margin-left: 36pt;
    }

    .FLCNOTE, .FLCEXCEPTION {
        margin-left: 18pt !important;
    }

    .FLCSECONDPARA {
        text-align: justify;
        text-indent: 18pt;
    }

    .FLCSECTION_l3_L6 {
        text-align: justify;
        margin-left: 18pt;
    }

    /* main.css*/
    .chapter_header_styling {
        color: #444444;
        font-size: 18px;
        font-weight: bolder;
        font-family: 'Open Sans';
    }


    /*Style sheet*/

    .abbrevation {
        clear: both;
    }

    .myannotation_section {
        padding-left: 48px !important;
        padding-top: 16px;
    }

    .bookmark {
        margin-left: 5px;
    }

    .sectionContent .bold {
        display: block;
        float: left;
        max-width: 93%;
    }

    .bf_heading {
        font-size: 18px !important;
        font-weight: bold !important;
    }

    .i-codes-botton {
        cursor: pointer;
    }

    .history_click {
        cursor: pointer;
    }


    .OHIOBULLET_L7 {
        margin-left: 50pt;
    }

    .OHIOBULLET_L8 {
        margin-left: 70pt;
    }

    .OHIOBULLET_L9 {
        margin-left: 80pt;
    }

    .ICCBULLET_L7 {
        margin-left: 50pt;
    }

    .ICCBULLET_L8 {
        margin-left: 70pt;
    }

    .ICCBULLET_L9 {
        margin-left: 80pt;
    }

    .plus-img {
        background-image: url("../../images/plus-icon.png");
        background-position: 0px 0px;
        background-repeat: no-repeat;
        padding-left: 25px;
    }

    .minus-img {
        background-image: url("../../images/minus-icon.png");
        background-position: 4px 0px;
        background-repeat: no-repeat;
        padding-left: 25px;
    }

    /* add book specific css property to resolve the formatting issues */
    .IB_class_center {
        text-align: center;
    }

    .IB_class_left {
        text-align: left;
    }

    .IB_class_right {
        text-align: right;
    }

    .IB_class_left_ind_12 {
        padding-left: 12px;
    }

    .IB_class_left_ind_18 {
        padding-left: 18px;
    }

    .IB_class_tab_count_1 {
        padding-left: 0.4em;
    }

    .IB_class_tab_count_2 {
        padding-left: 0.8em;
    }

    .IB_class_first_ind_-27 {
        padding-left: 27px;
    }

    .IB_class_left_ind_99 {
        padding-left: 99px;
    }

    .IB_class_left_ind_36 {
        padding-left: 36px;
    }

    .IB_class_left_ind_72 {
        padding-left: 72px;
    }

    .IB_class_left_ind_54 {
        padding-left: 54px;
    }

    // Table/Figure label CSS
    .FLCFIGURE_L1,
    .FLCFIGURE_L2,
    .FLCFIGURE_L3,
    .FLCFIGURE_L4,
    .FLCFIGURE_L5,
    .FLCFIGURE_L6,
    .FLCFIGURE_L7,
    .FLCFIGURE_L8,
    .FLCFIGURE_L9,
    .FLCFIGURE_L10,
    .FLCFIGURE_L11,
    .FLCFIGURE_L12,
    .FLCFIGURE_L13,
    .FLCFIGURE_L14,
    .FLCFIGURE_L15,
    .ICCFIGURE_L1,
    .ICCFIGURE_L2,
    .ICCFIGURE_L3,
    .ICCFIGURE_L4,
    .ICCFIGURE_L5,
    .ICCFIGURE_L6,
    .ICCFIGURE_L7,
    .ICCFIGURE_L8,
    .ICCFIGURE_L9,
    .ICCFIGURE_L10,
    .ICCFIGURE_L11,
    .ICCFIGURE_L12,
    .ICCFIGURE_L13,
    .ICCFIGURE_L14,
    .ICCFIGURE_L15,
    .ICCTABLE_L1,
    .ICCTABLE_L2,
    .ICCTABLE_L3,
    .ICCTABLE_L4,
    .ICCTABLE_L5,
    .ICCTABLE_L6,
    .ICCTABLE_L7,
    .ICCTABLE_L8,
    .ICCTABLE_L9,
    .ICCTABLE_L10,
    .ICCTABLE_L11,
    .ICCTABLE_L12,
    .ICCTABLE_L13,
    .ICCTABLE_L14,
    .ICCTABLE_L15
    {
        margin-top: 20px;
        text-align: center;
        font-weight: bold !important;
    }

    figure {
        table {
            border: 1px solid #CCCCCC;
            td {
                border: 1px solid #CCCCCC;
            }
        }
    }
}
