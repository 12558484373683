
#toc-wrapper-container {
    .chapter-wrapper {
        overflow-y: scroll;
    }

}
.toc-wrapper {
    .mini-text {
        font-size: 10px;
    }
    .v-tabs {
        .v-tabs__container {
            height: auto;
        }
        .active-tab-border {
            border: 1px solid #EEEEEE!important;
        }
        .v-tab{
            font-weight: 600 !important;
            letter-spacing: normal;
        }
    }
}
#chapter-wrap-header{
    .v-slide-group__prev,.v-slide-group__next{
        display: none;
    }
    .v-tab--active{
        background: unset !important;
    }
}
.codes-content-tabs {
    .v-tab:hover, .v-tab:focus {
        &:before {
            opacity: .46 !important;
        }
        &:not(.v-tab--active) {
            color: rgb(15, 15, 15) !important;
            >.v-btn {
                color: rgb(15, 15, 15) !important;
            }
            >.v-icon {
                color: rgb(15, 15, 15) !important;
            }
        }
    }
    .v-tab--active:hover, .v-tab--active:focus {
        &:before {
            opacity: .12 !important;
        }
        font-weight: 800 !important;
    }
}
