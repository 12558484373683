
    .steps_container {
/*        max-height: 505px;*/
        overflow: auto;
        .v-slider__ticks-container {
            background: rgba(0,0,0,.26);
        }
    }
    .books_menu {
      overscroll-behavior: contain;
    }
