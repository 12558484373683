.freeViewStyles {
    .url,
    .see,
    .abbr,
    .locator,
    .new_location,
    .table_reference,
    .cross_reference,
    .figure_reference,
    .section_reference,
    .section_reference_standard {
        pointer-events: none !important;
        cursor: text !important;
        text-decoration: none !important;
        color: black !important;
    }
    .relocated_to, {
        cursor: text !important;
        text-decoration: none !important;
        color: black !important;
    }
    .clonedchar {
        margin: 0!important;
        width: 75%!important;
    }
    @media screen and (max-width: 991px) {
        .clonedchar {
            width: 100%!important;
        }
    }
}
