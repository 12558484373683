
path:hover {
    cursor: pointer;
}

.default {
  width: 20px;
  height: 20px;
  background: #38835e;
}

#map {
  min-height: 400px;
}
