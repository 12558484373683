
#chapter-wrapper-mobile {
    overflow-y: scroll;
}
#mobile-chapter-header {
    .v-tabs {
        .v-tabs__container {
            height: auto;
        }
        .active-tab-border {
            border: 1px solid #EEEEEE!important;
        }
        .v-tab{
            font-weight: 600 !important;
            letter-spacing: normal;
        }
    }
    .v-slide-group__prev,.v-slide-group__next{
        display: none;
    }
    .v-tab--active{
        background: unset !important;
    }
}

