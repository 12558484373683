
#digital-reference-navigation{
    z-index: 10;
    .v-toolbar__content{
        display: grid !important;
    }
    @media (min-width: 600px) and (max-width: 960px){
        .v-toolbar__content, .v-toolbar__extension{
            padding: 20px 20px;
        }
    }
    @media screen and (max-width: 600px) {
        .v-toolbar__content, .v-toolbar__extension{
            padding: 8px 8px;
        }
    }
    @media screen and (max-width: 300px) {
        .logo-img{
            max-height: 18px !important;
        }
    }
}
