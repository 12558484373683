<template>
    <div :id="getId()">
        <v-card outlined>
            <v-card-text class="py-0">
                <v-row>
                    <v-col class="mb-0 pb-0">

                        <div class="float-right content-search-action-icons">
                            <span v-if="showIcon">
                            <v-tooltip v-if="$store.getters.getDebugShowResultScores" left>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                            v-on="on"
                                            color="red"
                                            class="mr-2"
                                            @click="openFullExplanationLink"
                                    >
                                        help_outline
                                    </v-icon>
                                </template>
                                <div class="tooltip-content">
                                    Score: {{ resultScore }}
                                    <br>
                                    Term Frequency: {{ termFrequency }}
                                </div>
                            </v-tooltip>

                            <v-tooltip
                            top
                            transition="fade-transition"
                            v-model="tooltips.input">
                                <template v-slot:activator="{ on }">
                                    <item-link
                                    :meta="meta"
                                        :documentId="documentId"
                                        :breadcrumbs="breadcrumbs ? breadcrumbs : {}">
                                        <v-icon
                                            v-on="on"
                                            class="right mr-2"
                                            tabindex="0"
                                            @keyup.enter="showTooltip('input')"
                                            @keyup.space="showTooltip('input')">
                                            input
                                        </v-icon>
                                        </item-link>
                                </template>
                                <span class="tooltip-content"
                                @mouseenter="showTooltip('input')"
                                @mouseleave="hideTooltip('input')"
                                @focus="showTooltip('input')"
                                @blur="hideTooltip('input')">Jump to this Section</span>
                            </v-tooltip>

                            <v-tooltip
                            top
                            transition="fade-transition"
                            v-model="tooltips.file">
                                <template v-slot:activator="{ on: onMenu }">

                                     <v-menu offset-y transition="0" location="start">
                                        <template v-slot:activator="{ on: onTooltip }">
                                                <v-icon class="right mr-2"
                                                tabindex="0"
                                                @keyup.enter="showTooltip('file')"
                                                @keyup.space="showTooltip('file')"
                                                v-on="{ ...onMenu, ...onTooltip }">file_copy</v-icon>
                                        </template>

                                        <v-list>
                                          <v-list-item
                                                  @click="sectionCopyAction(false)"
                                                  v-second-event="{ method: 'registerClick', value: '2' }"
                                          >
                                              Copy this Section to Clipboard (text only)
                                          </v-list-item>
                                          <v-list-item
                                                  @click="sectionCopyAction(true)"
                                                  v-second-event="{ method: 'registerClick', value: '2' }"
                                          >
                                              Copy this Section to Clipboard (with formatting)
                                          </v-list-item>
                                        </v-list>
                                      </v-menu>

                                </template>

                                <span class="tooltip-content"
                                @mouseenter="showTooltip('file')"
                                @mouseleave="hideTooltip('file')"
                                @focus="showTooltip('file')"
                                @blur="hideTooltip('file')">Copy this Section to Clipboard</span>
                             </v-tooltip>
                            <v-tooltip top
                            transition="fade-transition"
                            v-model="tooltips.print">
                                <template v-slot:activator="{ on }">
                                    <v-icon @click="sectionPrintAction" v-second-event="{ method: 'registerClick', value: '3' }"
                                            class="right mr-2" v-on="on"
                                            tabindex="0"
                                            @keyup.enter="showTooltip('print')"
                                            @keyup.space="showTooltip('print')">local_printshop
                                    </v-icon>
                                </template>
                                <span class="tooltip-content"
                                @mouseenter="showTooltip('print')"
                                @mouseleave="hideTooltip('print')"
                                @focus="showTooltip('print')"
                                @blur="hideTooltip('print')">Print this Section</span>
                            </v-tooltip>
                            <v-tooltip top
                            transition="fade-transition"
                            v-model="tooltips.link">
                                <template v-slot:activator="{ on }">
                                    <v-icon @click="sectionShareAction" v-second-event="{ method: 'registerClick', value: '4' }"
                                            class="right mr-2" v-on="on"
                                            tabindex="0"
                                            @keyup.enter="showTooltip('link')"
                                            @keyup.space="showTooltip('link')">link
                                    </v-icon>
                                </template>
                                <span class="tooltip-content"
                                @mouseenter="showTooltip('link')"
                                @mouseleave="hideTooltip('link')"
                                @focus="showTooltip('link')"
                                @blur="hideTooltip('link')">Share this Section</span>
                            </v-tooltip>
                            <v-tooltip top v-if="canBeBookmarked"
                            transition="fade-transition"
                            v-model="tooltips.bookmark">
                                <template v-slot:activator="{ on }">
                                    <v-icon @click="sectionBookmarkAction(getId())" v-second-event="{ method: 'registerClick', value: '5' }"
                                            class="right mr-2" v-on="on"
                                            tabindex="0"
                                            @keyup.enter="showTooltip('bookmark')"
                                            @keyup.space="showTooltip('bookmark')">
                                        {{note.bookmarks.length ? 'bookmark' : 'bookmark_border'}}
                                    </v-icon>
                                </template>
                                <span class="tooltip-content"
                                @mouseenter="showTooltip('bookmark')"
                                @mouseleave="hideTooltip('bookmark')"
                                @focus="showTooltip('bookmark')"
                                @blur="hideTooltip('bookmark')">Bookmark this Section</span>
                            </v-tooltip>
                        </span>
                        <v-icon @click="showActionIcons" class="waffle-container">apps</v-icon>
                            </div>
                        <div class="content-result-row">
                            <item-header
                                :title="getHighlightTitle(highlight)"
                                :meta="meta"
                                :documentId="documentId"
                                :breadcrumbs="breadcrumbs"
                                :search="search">
                            </item-header>
                            <item-content
                                :title="getHighlightTitle(highlight)"
                                :content="getContent()"
                                :sectionId="sectionId"
                                :type="getType()"
                                :breadcrumbs="breadcrumbs">
                            </item-content>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="mt-0 pt-0">
                        <p class="mb-0">
                            <item-breadcrumb
                                :meta="meta"
                                :breadcrumbs="breadcrumbs ? breadcrumbs : {}"
                                :documentId="documentId">
                            </item-breadcrumb>
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <note-list :data="note"></note-list>
        <v-snackbar top right color="success" v-model="snackbar" :timeout="timeout">
            Content was copied to buffer
        </v-snackbar>
    </div>
</template>

<script>
    import ItemHeader from './item_header';
    import ItemBreadcrumb from './item_breadcrumb';
    import ItemContent from './item_content';
    import ItemLink from './item_link.vue'
    import Triggers from '../../../dashboard/quick_access/mixins/section_triggers';
    import NoteList from '../../../titles/partials/noteList';
    import Constants from "../../../../constants";

    export default {
        name: 'ContentResultsItem',
        props: ['meta', 'result', 'scrollKey', 'search'],
        data: function () {
            return {
                bookmarkIcon: 'bookmark_border',
                loaded: false,
                loadingNotes: true,
                isContentSearch: true,
                showIcon: false,
                tooltips: {
                    input: false,
                    file: false,
                    print: false,
                    link: false,
                    bookmark: false
                }
            }
        },
        mixins: [Triggers],
        components: {
            ItemHeader,
            ItemBreadcrumb,
            ItemContent,
            ItemLink,
            NoteList
        },
        computed: {
            canBeBookmarked() {
                return _.get(this.meta, 'bookcontenttype') !== Constants.contentTypeIccEsXml;
            },
            documentId() {
                return this.meta.documentid;
            },
            sectionId() {
                return this.getSearchResultWithTypes()._source.contentid;
            },
            chapterId() {
                return this.breadcrumbs.chapterId;
            },
            hasTable() {
                return 'Yes' === this.getSearchResultWithTypes()._source.hastable;
            },
            note() {

                const bookmarks = this.$store.getters.getBookmarks.filter((bookmark) => {
                    return this.sectionId && this.sectionId == bookmark.section_id;
                });

                const annotations = this.$store.getters.getAnnotations.filter((annotation) => {
                    return this.sectionId && this.sectionId == annotation.section_number;
                });

                return {bookmarks, annotations};
            },
            score() {
                return this.getSearchResultWithTypes()._score;
            },
            highlight() {
                return this.getSearchResultWithTypes().highlight;
            },
            breadcrumbs() {
                const source = this.getSearchResultWithTypes()._source;
                return source.breadcrumbs ? source.breadcrumbs : {};
            },
            resultScore() {
                return _.get(this.getSearchResultWithTypes()._explanation, "value", "n/a");
            },
            termFrequency() {

                const meta = _.findDeep(this.getSearchResultWithTypes()._explanation, (value, key, parentValue, context) => {
                    return (value + "").includes('tf,');
                });

                return _.get(meta, 'parent.details.0.value', 'n/a');
            }
        },
        methods: {
            /**
             *
             * @returns {ContentSearchResult}
             */
            getSearchResultWithTypes() {
                return this.result;
            },
            showActionIcons: function() {
                this.showIcon = !this.showIcon;
            },
            getContent() {
                let content = '';

                if (this.isXMLType()) {
                    if (this.highlight && this.highlight.content) {
                        content = this.highlight.content[0];
                    }
                    if (this.highlight && this.highlight.contentExactMatch) {
                        content = this.highlight.contentExactMatch[0];
                    }
                    if (this.highlight && this.highlight.contentProximityMatch) {
                        content = this.highlight.contentProximityMatch[0];
                    }
                    if(_.isEmpty(content) && this.meta && this.meta.content) {
                        content = this.meta.content;
                    }
                } else {
                    if (this.highlight && this.highlight['attachment.contentBook']) {
                        content = this.highlight['attachment.contentBook'];
                    } else if (this.highlight && this.highlight['attachment.content']) {
                        content = this.highlight['attachment.content'];
                    }
                }
                return content;
            },
            getType() {
                return this.meta.bookcontenttype;
            },
            getId() {
                return this.documentId+'_'+this.sectionId+'-'+this.scrollKey;
            },
            isXMLType() {
                return this.meta.contentid !== undefined;
            },
            registerClick: function(actionTaken) {
                this.$http.get(Routing.generate('content_search_result_search_click', {
                    resultBookId: this.documentId,
                    resultSectionNumber: this.breadcrumbs.subSection,
                    resultSectionTitle: this.breadcrumbs.title,
                    actionTaken: actionTaken
                }));
            },
            getHighlightTitle(highlight) {
                return highlight ? highlight.title : '';
            },
            openFullExplanationLink() {
                if (this.explanation) {
                    let formattedJson = JSON.stringify(this.explanation, null, 3);

                    let win = window.open("");
                    win.document.body.innerHTML = `<title>Explaining</title> <pre>${formattedJson}</pre>`;
                }
            },
            showTooltip(key) {
                this.$set(this.tooltips, key, true);
            },
            hideTooltip(key) {
                this.$set(this.tooltips, key, false);
            }
        }
    }
</script>

<style>
    table, table thead th, table tbody, table tbody td {
        font-size: 14px;
    }

    table {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-collapse: collapse;
    }


    table thead th {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 2px;
    }

    table tbody {
        font-size: inherit;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    table tbody td {
        margin-top: 1.0em;
        margin-bottom: 1.0em;
        padding: 0.2em;
        position: relative;
    }

    figure figcaption .table_note {
        font-weight: normal;
        font-style: normal;
        font-size: inherit;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    th.box_table, td.box_table {
        border: solid 1px;
        padding: 0.2em;
    }

    .hside_table {
        border-bottom: solid 1px;
        border-top: solid 1px;
        padding: 0.2em;
    }

    /* border Style */
    .border_left, .border_right, .border_top, .border_bottom {
        border-right: 0px;
        border-left: 0px;
        border-bottom: 0px;
        border-top: 0px;
    }

    .border_right {
        border-right: solid 1px !important;
    }

    .border_left {
        border-left: solid 1px !important;
    }

    .border_top {
        border-top: solid 1px !important;
    }

    .border_bottom {
        border-bottom: solid 1px !important;
    }

    .bold {
        font-weight: bold;
    }

    .item-result .note-list {
        width: 100%;
    }
    .section-content-body {
        width: 100%;
    }

    p.table_number, p.table_title {
        font-weight: bold;
        font-style: normal;
        text-align: left;
        margin: 10px 0 0 0;
    }

    .content-search-action-icons {
        position: relative;
        z-index: 8;
    }

    .item-result img {
        max-width: 100%;
    }

    .waffle-container {
        position: fixed;
    }
    /** hide extra headings in search result page as title already there */
    .content-result-row h2.table_number,
    .content-result-row h2.table_title,
    .content-result-row h1.level1,
    .content-result-row h1.level2,
    .content-result-row h1.level3,
    .content-result-row h1.level4,
    .content-result-row h1.level5,
    .content-result-row h1.level6,
    .content-result-row h2.frontmatter_subhead,
    .content-result-row h2.tgroup
        {
            display: none;
        }
</style>
