
#navigation-container {
    max-width: 320px;
}
.v-tab {
    min-width: unset;
    text-transform: unset;
    font-size: 12px;
    padding: 0 10px;
}
