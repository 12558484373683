
.flex-card {
  display: flex;
  flex-direction: column;
}

.flex-card > .v-card__text {
  overflow-y: auto;
}

.flex-card .v-toolbar {
  flex: 0;
}
