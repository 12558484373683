
.global-search-input {
    position: relative;
    width: 100%;
    max-width: 640px;
}
.search-dropdown {
  position: absolute;
  width: 97.5%;
  margin-top: 0.6em;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 !important;
  overscroll-behavior: contain;
}
.search-btn {
  background-color: #f5f5f5;
  margin: 0 8px;
  border: none;
  box-shadow: none;
  min-width: 120px;
  text-transform: none;
}
.search-btn:hover {
  background-color: #e0e0e0;
}
.adv-search-btn {
    height: 39px !important;
}
@media (max-width: 960px) {
  .search-dropdown {
    max-height: 50vh !important;
  }
}

