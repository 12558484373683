
.chapter_styling {
    .tag-label {
        display: inline-block;
        text-decoration: none!important;
        .v-chip__content {
            color: #FFFFFF!important;
        }
    }
}
