
.note-list{
    .v-expansion-panel__header {
        background: #EEEEEE;
    }
    .v-expansion-panel__header__icon i {
        color: rgba(0,0,0,.87)!important;
    }
    .icons .v-icon:hover {
        color: #0B5940;
    }
}
.notes-expansion-panel-new {
    .v-expansion-panel::before {
        box-shadow: unset!important;
    }
    .v-expansion-panel-content__wrap {
        padding: 0;
    }
}
