
.premium-team-container {
    max-width: 1200px;

    margin: 0 auto;
    img {
        max-width: 100%;
    }
    .oxygen {
        font-family: Oxygen;
    }
}
@media (min-width: 685px) {
    h1 {
        font-size: 54px;
        line-height: 68px;
    }
    h2 {
        font-size: 32px;
        line-height: 42px;
    }
    h3 {
        font-size: 23px;
    }
    h4 {
        font-size: 20px;
    }
}
@media only screen and (min-width: 685px) and (max-width: 820px)  {
     h1 {
        font-size: 42px;
    }
}
@media (max-device-width: 684px) {
    .access-display {
        padding: 0px !important;
        h1 {
            font-size: 18px;
        }
        h2 {
            font-size: 13px;
        }
        h3 {
            font-size: 10px;
        }
    }
    .quoted-wrapper{
        padding: 30px !important;
    }
}
.option-text {
    font-size: 20px;
    line-height: 25px;
}
.access-highlights {
    background: #FEF5E4 !important;
}
.v-divider{
    border-color: #fff;
    border-width: 1px;
}
.quoted{
    font-size: 35px;
}

