
.premium-tag-category-item {
    position: relative;
}
.tooltip-codeinsights-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;   /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-width: 100%;
}
