
.companyLogos {
    .VueCarousel-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.logoContainer {
    max-height: 40px;
}
.carousel-button {
    top: 0;
    .v-btn__content {
        i {
            font-size:40px; 
            color: #0b5940;
        }
    }
}
