
.exam-feature-container {
    padding-bottom: 0px !important;
    img {
        max-width: 100%;
    }
    .oxygen {
        font-family: Oxygen;
    }
    @media (min-width: 641px) {
        h1 {
            font-size: 48px;
            line-height: 56px;
        }
        h2 {
            font-size: 24px;
            line-height: 39px;
        }
    }
}
