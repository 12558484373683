<template>
    <v-container>
        <v-row>
            <v-col>
                <h1 class="primary--text display-1">My Cart</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="subtitle-2">
                <p>Premium cart only reflects Digital Codes Premium products that are in the users cart.  Any non Digital Codes Premium products are still present in the cart once the user navigates to the storefront</p>
            </v-col>
        </v-row>
        <template v-if="items.length && isMobileAndTablet">
            <text-membership-discount></text-membership-discount>
            <cart-summary
                :item-count="getItemCount()"
                :total-price="getTotalPrice()">
            </cart-summary>
            <related-subscriptions-mobile :skus="cartBookSkus"></related-subscriptions-mobile>
        </template>
        <v-row justify="center">
            <template v-if="loaded">
                <template v-if="items.length">
                    <v-col md="9">
                        <v-card outlined>
                            <v-card-text>
                                <v-data-iterator
                                    :items="items"
                                    :options.sync="options"
                                    :page="page"
                                    content-tag="v-layout"
                                    hide-default-footer
                                    row class="black--text"
                                    wrap>
                                    <template v-slot:header>
                                        <v-card-title class="pt-0">
                                            <span class="title font-weight-thin">
                                              {{getItemCount()}} Items
                                            </span>
                                            <v-spacer></v-spacer>
                                            <text-membership-discount v-if="!isMobileAndTablet"></text-membership-discount>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                    </template>
                                    <template v-slot:item="props">
                                        <cart-item
                                            :itemLoading="itemLoading"
                                            v-if="!isMobileAndTablet"
                                            :item="props.item"
                                            :itemPrice="pricePerItem(props.item, isMember)"
                                            :itemTotal="getProductTotalPrice(props.item, isMember)">
                                        </cart-item>
                                        <cart-item-mobile
                                            v-if="isMobileAndTablet"
                                            :item="props.item"
                                            :itemPrice="pricePerItem(props.item, isMember)"
                                            :itemTotal="getProductTotalPrice(props.item, isMember)">
                                        </cart-item-mobile>
                                    </template>
                                    <template v-slot:footer>
                                        <div class="text-right">
                                            <p class="title pt-4">Subtotal ({{getItemCount()}} Items):
                                                <span class="font-weight-bold">${{getTotalPrice()}}</span>
                                            </p>
                                        </div>
                                        <div class="text-right">
                                            <v-btn
                                                light
                                                depressed
                                                color="accent2"
                                                @click="goToCheckout">
                                                Proceed to Checkout
                                            </v-btn>
                                        </div>
                                        <v-divider class="mt-8"></v-divider>
                                        <v-container>
                                            <v-row class="mt-2 relative" align="center" justify="center">
                                                <span>Items per page</span>
                                                <v-menu offset-y>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            text
                                                            class="ml-2"
                                                            v-on="on">
                                                            {{ options.itemsPerPage }}
                                                            <v-icon>expand_more</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item
                                                            v-for="(number, index) in itemsPerPageArray"
                                                            :key="index"
                                                            @click="updateItemsPerPage(number)">
                                                            <v-list-item-title>{{ number }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                                <v-spacer></v-spacer>
                                                <span class="mr-4">
                                                    Page {{ page }} of {{ numberOfPages }}
                                                </span>
                                                <v-btn
                                                    icon
                                                    role="button"
                                                    aria-label="Left icon button for previous page"
                                                    class="mr-1"
                                                    @click="formerPage">
                                                    <v-icon>chevron_left</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    icon
                                                    role="button"
                                                    aria-label="Right icon button for next page"
                                                    class="ml-1"
                                                    @click="nextPage">
                                                    <v-icon>chevron_right</v-icon>
                                                </v-btn>
                                            </v-row>
                                        </v-container>
                                    </template>
                                </v-data-iterator>
                            </v-card-text>

                            <v-overlay v-model="isOverlayVisible" absolute opacity="0.25" class="cursor-wait">
                                <v-progress-circular
                                        indeterminate
                                        size="64"
                                ></v-progress-circular>
                            </v-overlay>

                        </v-card>
                    </v-col>
                    <v-col v-if="!isMobileAndTablet">
                        <cart-summary :item-count="getItemCount()" :total-price="getTotalPrice()"></cart-summary>
                        <related-subscriptions :skus="cartBookSkus"></related-subscriptions>
                    </v-col>
                </template>
                <template v-else>
                    <v-col xl="4">
                        <v-card outlined>
                            <v-card-text class="text-center">
                                <p>Your cart is empty.</p>
                                <p><a @click="showFeaturedTitles">Click here</a> to search the titles.</p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </template>
            </template>
            <template v-else>
                <v-col cols="auto">
                    <v-progress-circular
                        indeterminate
                        color="primary">
                    </v-progress-circular>
                </v-col>
            </template>
        </v-row>
        <v-dialog
            v-model="removeModal"
            max-width="350">
            <v-card>
                <v-card-title
                    class="primary white--text"
                    :class="{
                        'headline': !isMobilePort,
                        'caption mobile-title': isMobilePort
                    }">
                    Remove Item from cart?
                    <v-spacer></v-spacer>
                    <v-icon small class="mr-2 accent2--text" @click="removeModal = false">close</v-icon>
                </v-card-title>
                <v-card-text class="pl-2">
                    <v-container>
                        <v-row>
                            <v-col>
                                Are you sure you want to remove this item?
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        primary
                        @click="removeModal = false">
                        Cancel
                    </v-btn>
                    <v-btn
                        color="red darken-1"
                        @click="removeItem"
                        class="white--text">
                        <template v-if="!removeLoading">
                            Remove
                        </template>
                        <template v-else>
                            <v-progress-circular
                                indeterminate
                                :size="20"
                                color="white">
                            </v-progress-circular>
                        </template>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="loader"
            max-width="290">
            <v-card>
                <div class="text-center pt-6">
                    <v-progress-circular
                        indeterminate
                        color="primary">
                    </v-progress-circular>
                    <p>Updating your cart ...</p>
                    <br />
                </div>
            </v-card>
        </v-dialog>
        <checkout-modal>
        </checkout-modal>
    </v-container>
</template>
<script>
    import TextMembershipDiscount from './partials/textMembershipDiscount';
    import CartSummary from './partials/cartSummary';
    import CartItem from './partials/cartItem';
    import CartItemMobile from './partials/cartItemMobile';
    import RelatedSubscriptions from './partials/relatedSusbcriptions';
    import RelatedSubscriptionsMobile from './partials/relatedSusbcriptionsMobile';
    import CheckoutModal from './partials/checkout_modals';

    import Mobile from '../inc/mixins/mobile'
    import Prices from '../inc/mixins/pricesHelper';
    import axios from "axios";

    export default {
        data() {
            return {
                itemsPerPageArray: [15, 20, 25],
                options: {
                    itemsPerPage: 15
                },
                page: 1,
                removeModal: false,
                editItem: {},
                totalPrice: 0,
                items: [],
                loader: false,
                itemLoading: '',
                removeLoading: false,
                cartBookSkus: []
            };
        },
        components: {
            TextMembershipDiscount,
            CartSummary,
            CartItem,
            CartItemMobile,
            RelatedSubscriptions,
            RelatedSubscriptionsMobile,
            CheckoutModal
        },
        mixins: [Prices, Mobile],
        computed: {
            numberOfPages () {
                return Math.ceil(this.items.length / this.options.itemsPerPage)
            },
            isOverlayVisible() {
                return this.$store.getters.isCartUpdateInProgress;
            }
        },
        methods: {
            updateItemsPerPage (number) {
                this.options.itemsPerPage = number
            },
            nextPage () {
                if (this.page + 1 <= this.numberOfPages) this.page += 1
            },
            formerPage () {
                if (this.page - 1 >= 1) this.page -= 1
            },
            getItemCount() {
                return this.$session.has('cart_items_count')
                       ? this.$session.get('cart_items_count')
                       : 0;
            },
            isLoggedIn() {
                return this.$store.getters.getLoggedIn !== '';
            },
            getProducts(){
                this.$http.get(Routing.generate('get_cart_products'))
                    .then(response => {
                        EventBus.fire('set-cart-items-count', {
                            type: 'exact',
                            qty: response.data.qty
                        });
                        this.items = response.data.products;
                        this.setCartBookSkus(response.data.products);
                        this.prices = response.data.prices;
                        this.isMember = response.data.prices.is_member;
                        this.loaded = true;
                        this.loader = false;
                    });
            },
            setCartBookSkus(cartItems) {
                let _this = this;
                _.forEach(cartItems, function(item) {
                    _this.cartBookSkus.push(item.sku);
                });
            },
            refreshCartProducts() {
                this.loader = true;
                this.getProducts();
            },
            incrementQuantity(data) {
                data.quantity = _.toInteger(data.quantity) + 1;
                EventBus.fire('set-cart-items-count', {
                    type: 'increment',
                    qty: 1
                });
                this.editProduct(data,false);
            },
            decrementQuantity(data) {
                if (data.quantity > 1) {
                    data.quantity = _.toInteger(data.quantity) - 1;
                    EventBus.fire('set-cart-items-count', {
                        type: 'decrement',
                        qty: 1
                    });
                    this.editProduct(data, false);
                }
            },
            openRemoveModal(item) {
                this.removeModal = true;
                this.editItem = item;
            },
            editProduct(product, isDuration) {
                let data = {
                    'cart_id': product.cart_id,
                    'item_id': product.item_id,
                    'quote_id': product.quote_id,
                    'quantity': product.quantity,
                    'sku': product.document_info.sku,
                    'subscription_type': product.subscription_type,
                    'update_duration': isDuration
                };
                this.itemLoading = product.document_info.sku+product.item_id;
                this.$store.commit('setCartUpdateInProgress', true);

                const routeUri = Routing.generate('update_cart_product', {product: data});
                axios.get(routeUri)
                    .then(response => {
                        let itemIndex = this.items.indexOf(product);
                        this.items[itemIndex].item_id = response.data.item_id;
                        this.itemLoading = '';
                    })
                    .finally(() => {
                        this.$store.commit('setCartUpdateInProgress', false);
                    })
            },
            removeItem() {
                EventBus.fire('set-cart-items-count', {
                    type: 'decrement',
                    qty: this.editItem.quantity
                });
                this.removeLoading = true;
                this.$store.commit('setCartUpdateInProgress', true);
                const routeUri = Routing.generate('remove_cart_product', {
                    quoteId: this.editItem.quote_id,
                    itemId: this.editItem.item_id
                });
                axios.get(routeUri).then(response => {
                    this.items.splice(this.items.indexOf(this.editItem), 1);
                    this.editItem = {};
                    this.removeModal = false;
                    this.removeLoading = false;
                }).finally(() => {
                    this.$store.commit('setCartUpdateInProgress', false);
                });
            },
            changeDuration(duration, product) {
                let itemIndex = this.items.indexOf(product);
                this.items[itemIndex].subscription_type = duration.value;
                this.editProduct(this.items[itemIndex], true);
            },
            showFeaturedTitles() {
                window.location = Routing.generate('search_page');
            },
            goToCheckout() {
                EventBus.fire('go_to_checkout');
            }
        },
        created(){
            this.getProducts();
            EventBus.listen('show-cart-loader', () => this.loaded = false);
            EventBus.listen('get-products', () => this.refreshCartProducts());
            EventBus.listen('change-duration', (data) => this.changeDuration(data['duration'], data['item']));
            EventBus.listen('increment-quantity', (data) => this.incrementQuantity(data));
            EventBus.listen('decrement-quantity', (data) => this.decrementQuantity(data));
            EventBus.listen('open-cart-remove-modal', (data) => this.openRemoveModal(data));

            Mousetrap.bind('o', () => {
                const temp = this.$store.getters.isCartUpdateInProgress;
                this.$store.commit('setCartUpdateInProgress', !temp);
            });

            Mousetrap.bind('r', () => {
                this.refreshCartProducts();
            });
        }
    }
</script>

<style>
    .v-btn--floating.v-btn--small .v-icon {
        font-size: 20px;
        font-weight: 700;
    }

    .quantity_change .v-btn--floating.v-btn--small {
        height: 35px;
        width: 35px;
    }

    a.remove_link {
        margin-top: 43px;
    }
</style>
