
.title-reference {

  .v-select__slot {
    width: auto !important;

    input {
      max-width: 0 !important;
    }
  }

  .v-input__slot {
    padding: 0px !important;
  }

  .mdi-menu-down::before {
    color: black !important;
  }
}
