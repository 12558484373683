/* when scrolling all the way to the top or to the bottom - prevent scrolling of the page itself */
.v-autocomplete__content {
  overscroll-behavior: contain;
}

/*
PRA-8497 make opacity from 0.9 to 1, and force black background
*/
.v-tooltip__content {
  opacity: 1 !important;
  background: black !important;
}
