
.header {
    background-image: url('../../images/texture_background.png');
    background-position: center center;
    background-size: 100% auto;
}
img {
    max-width: 100%;
}

#mobile_upsell {
  background: rgba(236, 241, 244, 0.5);
  background-blend-mode: normal;
}

.revit-promo-banner-mobile {
    h2 {
        font-size: 32px!important;
    }
    p {
        font-size: 24px!important;
    }
}
