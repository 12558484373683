
#navigation-app-mobile {
    .v-toolbar__content {
        padding: 0 8px;
    }
    .v-toolbar__extension {
        padding: 0 4px;
    }
    .logo {
        img {
            height: 40px;
            padding: 5px;
        }
    }
}
