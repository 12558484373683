
.title-card {

    .v-tooltip__content {
        pointer-events: initial;
    }

    .title-recent-badge {
        position: absolute;
        left: -9px; // carefully picked random value so on mobile left edge is not cut off or overlaps with tablet columns
        top: 1px;
        line-height: 20px !important;

        .v-badge__badge {
            padding: 2px 8px;
            line-height: 16px; // box size is set to 20px so 16px line height will center the text (excluding the padding)
            font-weight: 700;
        }
    }

    .additional-details {
        p {
            font-size: 12px;
            line-height: 1.5;
        }
    }
}
