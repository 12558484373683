@import './variables/colors';

.newIccStyles {
    .slanted-line-container {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
    }
    .slanted-line-container::before {
        content: "";
        position: absolute;
        top: 0;
        left: 10px;
        height: 100%;
        width: 108%;
        border-top: solid 1px;
        border-color: #000;
        transform: rotate(45deg);
        transform-origin: top left;
    }
    .slanted-line-top-text {
        top: 2px;
        right: 2px;
        position: absolute;
    }
    .slanted-line-bottom-text {
        bottom: 2px;
        left: 2px;
        position: absolute;
    }

    table {
        .no-border {
            border: none!important;
        }
    }

    // changed_level0, changed_level1, and changed_level 2 are the classes applied
    // to editor instances for sections with the matching data-changed attribute.

    .newIccStyles .changed_level0,
    .newIccStyles [data-changed*=changed_level0],
    *[data-changed*=changed_level0] *,
    .newIccStyles [data-changed*=changed_level0] .where div * {
        color: $changed-level-0 !important;
    }

    .newIccStyles figcaption
    .newIccStyles .changed_level0 section,
    .newIccStyles .changed_level0 figure,
    .newIccStyles [data-changed*=changed_level0] section,
    .newIccStyles [data-changed*=changed_level0] figure {
        color: #000;
    }

    .changed_level1,
    .changed_level1 *,
    *[data-changed*="changed_level1"],
    *[data-changed*="changed_level1"] * {
        color: $changed-level-1;
    }

    .changed_level2,
    .changed_level2 *,
    *[data-changed*="changed_level2"],
    *[data-changed*="changed_level2"] * {
        color: $changed-level-2 !important;
    }

    *[data-changed*="deleted_current"] {
        text-decoration: line-through;
    }

    // Colors of changed_current based on project level.
    // In some cases, we use an input instead of a CKEditor instances (e.g. figure alt text).
    // Those fields receive class "isNew".
    .projectLevel0,
    .projectLevel0SubsequentPrinting {
        ins:not([data-changed]),
        .changed_current,
        *[data-changed*="changed_current"],
        *[data-changed*="changed_current"] * {
            color: $changed-level-0;
        }
    }

    .projectLevel1 {
        ins:not([data-changed]),
        .changed_current,
        *[data-changed*="changed_current"],
        *[data-changed*="changed_current"] * {
            color: $changed-level-1;
        }
    }

    .projectLevel2 {
        ins:not([data-changed]),
        .changed_current,
        *[data-changed*="changed_current"],
        *[data-changed*="changed_current"] * {
            color: $changed-level-2;
        }
    }

    .projectLevel0 {
        :global {
            #proposal-tabs ins,
            .isNew {
                color: $changed-level-0;
            }
        }
    }

    .projectLevel1 {
        :global {
            #proposal-tabs ins,
            .isNew {
                color: $changed-level-1;
            }
        }
    }

    .projectLevel2 {
        :global {
            #proposal-tabs ins,
            .isNew {
                color: $changed-level-2;
            }
        }
    }

    .list ul {
        padding-left: 40px!important;
    }

    .no_mark p span.label {
        display: table-cell;
        width: 3em;
        text-align: right;
        padding-right: .5em;
    }

    .no_mark p {
        display: table;
    }

    .subchapter span.label:after,
    .run_in span.bold:after{
      content: ' ';
    }

    p[section-number-type="relocated"],
    span[section-number-type="relocated"],
    h1[section-number-type="relocated"]
    {
        color: $changed-level-0;
    }

    figcaption {
        font: normal 700 18px "Source Sans Pro", sans-serif;
        .figure_notes_SI {
            text-align: left;
        }
        span.correlated_section::before,
        span.correlated_section::after {
            content: ' ';
        }
        p {
            display: inline-block;
        }
    }

    .figure_note li > p > span.label {
        font-size: 8pt!important;
    }

    .equation_group div.where {
        text-align: left;
    }

    //Digital Codes Styles
    td.center {
        width: auto!important;
    }
    .align-center {
        text-align: center!important;
    }
    .promulgator {
        .section-action-wrapper {
            overflow: hidden;
        }
        .promulgator_acronym {
            float: left;
            font-size: 40px;
            font-weight: bold;
            margin: 15px 0 0 0!important;
        }
        .promulgator_contact_info{
            float: right;
            text-align: right;
            margin: 30px 10px 10px 10px;
            .promulgator_name,.street_address {
                float: right;
                width: 100%;
            }
            .city:after{
                content: ", ";
            }
            .state:after{
                content: " ";
            }
        }
        .referenced_standards {
            width: 100%;
            .reference_standard {
                float: left;
                clear: left;
                width: 100%;
                margin: 5px 0!important;
                padding: 0 10px 0 0;
                .reference_standard_number {
                    float: left;
                    width: 20%;
                    margin: 0!important;
                    text-align: left;
                }
                .reference_standard_title {
                    float: left;
                    width: 80%;
                    margin: 0!important;
                }
                .sections_referencing_standard {
                    clear: left;
                    margin: 0 0 0 20%!important;
                    .section_referencing_standard:after {
                        content: ", ";
                    }
                    .section_referencing_standard:last-child:after {
                        content: "";
                    }
                }
            }
        }
    }
    .index {
        .index_section_heading {
            cursor: text;
        }
        .index_term {
            color: inherit!important;
        }
    }
    // ICC 700
    .points_designation:before {
        content: "↦ ";
    }
    .points_designation {
        font-style: italic;
    }
    li > .exception {
        margin-left: 4em!important;
    }
    .index_title {
        font-weight: 700;
        text-align: center;
        font-size: 14px;
    }
    // 2020 LA Codes
    .deletion_marker_nonICC_LA:before {
        content:"➔";
        font-size: 15px;
        width: 0%;
        float: left;
        margin-left: -30px;
    }
    td .deletion_marker_nonICC_LA:before {
        content:"➔";
        font-size: 26px;
        width: 0%;
        position: absolute;
        left: -20px;
        top: -5px;
    }
    .informal th.center, .code th.center {
        width: unset !important;
    }
    .list_definition {
        ul.no_mark, ol.no_mark {
            margin-left: 30px !important;
        }
    }
    // MI Codes
    .MI_2015.relocated_to_nonICC:after, .MI_2018.relocated_to_nonICC:after {
        content: '•';
    }
    .MI_2015.new_location_nonICC:after, .MI_2018.new_location_nonICC:after {
        content: '••';
    }

    // MA Codes
    .deletion_marker_nonICC_MA:before {
        content:">";
        font-size: 18px;
        width: 0%;
        float: left;
        margin-left: -30px;
    }

    td .deletion_marker_nonICC_MA:before {
        content:">";
        font-size: 26px;
        width: 0%;
        position: absolute;
        left: -20px;
        top: -5px;
    }

    // Florida Ensuring the Safety of Existing Buildings in Florida
    .background_gray {
        background-color: #DCDCDC;
    }

    .background_black {
        color:white;
        background-color: black;
    }

    // XML ES Reports
    .es-xml-report-chapter {
        max-width: 1200px;
        margin: 0 auto;
        .es-xml-report-header {
            .flex-container {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: normal;
                align-items: normal;
                align-content: normal;
                .flex-items {
                    position: relative;
                }
                .flex-items:nth-child(1) {
                    display: block;
                    flex-grow: 6;
                    flex-shrink: 1;
                    flex-basis: auto;
                    align-self: auto;
                    order: 0;
                    width: 50%;
                }
                .flex-items:nth-child(2) {
                    display: block;
                    flex-grow: 6;
                    flex-shrink: 1;
                    flex-basis: auto;
                    align-self: auto;
                    order: 0;
                }
                .contact-flex-items {
                    text-align: center;
                }
                .flex-items50 {
                    width: 50%;
                    margin: 0 5px;
                }
            }
            .flex-footer-container {
                margin: 20px 0;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: normal;
                align-content: flex-end;
                .flex-footer-items {
                    width: 33%;
                    margin-right: 10%;
                }
                .flex-footer-items:nth-child(1) {
                    display: block;
                    flex-grow: 0;
                    flex-shrink: 1;
                    flex-basis: auto;
                    align-self: auto;
                    order: 0;
                }
                .flex-footer-items:nth-child(2) {
                    display: block;
                    flex-grow: 0;
                    flex-shrink: 1;
                    flex-basis: auto;
                    align-self: auto;
                    order: 0;
                }
                .flex-footer-items:nth-child(3) {
                    display: block;
                    flex-grow: 0;
                    flex-shrink: 1;
                    flex-basis: auto;
                    align-self: auto;
                    order: 0;
                }
                p {
                    margin: 2px 0;
                    font-weight: bold;
                    text-align: left;
                }
            }
            .margin-right5 {
                margin-right: 5%;
            }
            .esr-divider {
                background-color: #0A8EF3;
                height: 5px;
                width: 100%;
            }
            #ES_Map {
                width: 95%
            }
            .report-heading {
                color: #0b5940;
                margin-bottom: 0;
                font-size: 40px;
            }
            .revised {
                margin: 15px 0;
                font-size: 12px;
                p {
                    margin: 0 0 2px 0;
                }
            }
            .report-contains {
                font-size: 12px;
                p {
                    margin: 0 0 2px 0;
                }
            }
            .contact-container {
                background-color: #0A8EF3;
                padding: 20px;
                margin-bottom: 20px;
                p {
                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;
                }
                .white-text {
                    color: #FFFFFF;
                    a {
                        color: #FFFFFF!important;
                    }
                }
            }
            #ES_Product_Image {
                width: 100%;
                margin: 0 auto;
            }
            .esr-footer-column {
                width: 30%;
            }
        }
        .es-xml-product-image {
            .revised {
                margin: -70px 0 20px 0;
                width: 50%;
            }
        }
        .es-xml-no-product-image, .es-xml-product-image {
            .report-contains {
                position: absolute;
                margin: -45px 0 0 0;
                right: 0;
            }
        }
        .es-xml-no-map-or-product-image, .es-xml-no-map-image {
            .report-contains {
                float: right;
                margin: 15px 0;
            }
        }
        section.level1 h1 {
            text-align: left;
            color: #0A8EF3;
        }
        .es-table {
            overflow: auto;
            table {
                white-space: nowrap;
            }
        }
    }
    .es-xml-report-copyright {
        margin-top: 30px;
        .left {
            float: left!important;
        }
        .content_right {
            width: auto;
            float: right;
            margin-left: 20px;
            #ICC_Company_Logo {
                width: 90px;
                margin: 0 10px;
            }
            #ES_ANAB_Logo {
                width: 120px;
                margin-bottom: 5px;
            }
        }
    }

    // XML Flash Cards
    .flashcard {
        .section-action-wrapper {
            border: 1px solid lightgrey;
            margin-bottom: 20px!important;
            hr {
                display: none;
            }
        }
        .level1 {
            h1 {
                text-align: left;
            }
            details {
                background-color: lightgray;
                width: 80%;
                margin: 30px auto;
                padding: 10px;
                border-radius: 5px;
                p {
                    text-align: center;
                }
            }
        }
    }
    .commentary {
        border-left: 5px solid $accent2;
        position: relative;
        padding-left: 26px;
    }

    .commentary::before {
        position: absolute;
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTUsM0gxOUEyLDIgMCAwLDEgMjEsNVYxOUEyLDIgMCAwLDEgMTksMjFINUEyLDIgMCAwLDEgMywxOVY1QTIsMiAwIDAsMSA1LDNNMTEsN0EyLDIgMCAwLDAgOSw5VjE1QTIsMiAwIDAsMCAxMSwxN0gxM0EyLDIgMCAwLDAgMTUsMTVWMTRIMTNWMTVIMTFWOUgxM1YxMEgxNVY5QTIsMiAwIDAsMCAxMyw3SDExWiIgLz48L3N2Zz4=');
        background-size: contain;
        background-repeat: no-repeat;
        vertical-align: baseline;
        top: -3px;
        left: 0;
    }
}
