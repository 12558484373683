
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.nested-lists {
    .chapters-list {
        li {
            .toc-chapter-item {
                margin-left: 0 !important;
            }
        }
    }
}
.item-pointers-icon {
    &:not(.v-btn--text) {
        &:not(.v-btn--outlined) {
            &:focus {
                .v-btn__content {
                    i.v-icon {
                        background-color: #797976;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}
