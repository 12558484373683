
.tag-description {
    max-height: 130px;
    overflow-y: auto;
}
@media (max-width: 820px) {
    .tag-description {
        max-height: 300px;
        overflow-y: auto;
    }
}
