
.hero-container {
    img {
        max-width: 100%;
    }
    .oxygen {
        font-family: Oxygen;
    }
    @media (min-width: 641px) {
        h1 {
            font-size: 54px;
            line-height: 68px;
        }
        h2 {
            font-size: 32px;
            line-height: 42px;
        }
    }
}
