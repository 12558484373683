// Content styles
.user_note {
    border-radius: 4px;
    border: 1px solid #63AA8F;
    background-color: #f6f6f6;
    padding: 16px 32px;
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;

    font: italic 400 16px 'Source Sans Pro', sans-serif;
    line-height: 28px;
    margin-bottom: 12px;
}

.table_notes {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    background: #F6F9FC;
    .table_note {
        color: #424242;
        font: normal 400 10px 'Source Sans Pro', sans-serif;
        line-height: 18px;
        span.label {
            padding-right: 2pt;
            font-size: 8pt;
        }
        p {
            display: inline;
        }
    }
}

div.definitions {
    margin: 20px 0 0 0;
    div.term_group {
        .main_term {
            .definition {
                padding-left: 4pt;
            }
        }
    }
}


td[align="right"] {
    text-align:right;
}
p[data-changed="changed_level0"],
li[data-changed="changed_level0"],
ol[data-changed="changed_level0"],
.equation_group[data-changed="changed_level0"],
div[data-changed="changed_level0"] ul li p {
    color:blue;
}

p[data-changed="changed_level0"] span.term:after{
    content: " ";
}
.equation_content {
    float: left;
}
section[data-changed="changed_level0"] span.label {
    color: blue;
}
//this is a temporary fix, there is no other options for now
div[data-section-title="C101.1   Fire hazard severity form."],
div[data-section-title="114.1  Membership of board."] {
    color: blue;
}
.markTitle {
    background: yellow;
    max-width: fit-content;
}
.no_mark {
    list-style-type: none;
    font-weight: normal;
    text-align: left;
    margin-left: 10px;
    font-style: unset;
}

/* START: Category specific global styles */

.change_green {
    color: #08ff40 !important;
}

.change_orange {
    color: #ff8000 !important;
}

.change_aqua {
    color: #005D85 !important;
}

.changed_ICC *,
.change_virginia *,
.changed_virginia_table table td {
    color: blue;
}

.changed_virginia_table table {
    border-color: blue;
}

.changed_nonICC_NYC * {
    color: #f800f8;
}

.changed_nonICC_RI *,
.changed_nonICC_OR *,
.changed_nonICC_CHI *,
.changed_nonICC_WA *,
.changed_nonICC_CA *,
.changed_nonICC_VA *,
.changed_nonICC_SC *,
.changed_nonICC_Ohio *,
.changed_nonICC_IN *,
.changed_nonICC_NY *,
.changed_nonICC_MN *,
.changed_nonICC_RI,
.changed_nonICC_OR,
.changed_nonICC_CHI,
.changed_nonICC_WA,
.changed_nonICC_CA,
.changed_nonICC_VA,
.changed_nonICC_SC,
.changed_nonICC_Ohio,
.changed_nonICC_IN,
.changed_nonICC_NY,
.changed_nonICC_MN,
.changed_nonICC_CB {
    color: red;
}

.changed_nonICC_CHI_CBC *,
.changed_nonICC_OR_IFC *,
.changed_nonICC_WA2012 * {
    color: #035842;
}

.changed_nonICC_RI table,
.changed_nonICC_RI table td,
.changed_nonICC_RI table tr,
.changed_nonICC_RI table tr td,
.changed_nonICC_OR table,
.changed_nonICC_OR table td,
.changed_nonICC_OR table tr,
.changed_nonICC_OR table tr td,
.changed_nonICC_CHI table,
.changed_nonICC_CHI table td,
.changed_nonICC_CHI table tr,
.changed_nonICC_CHI table tr td,
.changed_nonICC_SC table,
.changed_nonICC_SC table td,
.changed_nonICC_SC table tr,
.changed_nonICC_SC table tr td,
.changed_nonICC_Ohio table,
.changed_nonICC_Ohio table td,
.changed_nonICC_Ohio table tr,
.changed_nonICC_Ohio table tr td,
.changed_nonICC_WA table,
.changed_nonICC_WA table td,
.changed_nonICC_WA table tr,
.changed_nonICC_WA table tr td,
.changed_nonICC_IN table,
.changed_nonICC_IN table td,
.changed_nonICC_IN table tr,
.changed_nonICC_IN table tr td,
.changed_nonICC_NY table,
.changed_nonICC_NY table td,
.changed_nonICC_NY table tr,
.changed_nonICC_NY table tr td,
.changed_nonICC_MN table,
.changed_nonICC_MN table td,
.changed_nonICC_MN table tr,
.changed_nonICC_MN table tr td {
    border-color: red !important;
    color: red !important;
}

.changed_nonICC_CHI_CBC table,
.changed_nonICC_CHI_CBC table td,
.changed_nonICC_CHI_CBC table tr,
.changed_nonICC_CHI_CBC table tr td,
.changed_nonICC_OR_IFC table,
.changed_nonICC_OR_IFC table td,
.changed_nonICC_OR_IFC table tr,
.changed_nonICC_OR_IFC table tr td,
.changed_nonICC_WA2012 table,
.changed_nonICC_WA2012 table td,
.changed_nonICC_WA2012 table tr,
.changed_nonICC_WA2012 table tr td {
    border-color: #035842 !important;
    color: #035842 !important;
}

.changed_nonICC_NYC table,
.changed_nonICC_NYC table td,
.changed_nonICC_NYC table tr,
.changed_nonICC_NYC table tr td {
    border-color: #f800f8 !important;
    color: #f800f8 !important;
}

// State-specific rules will override this
.deletion_marker_nonICC:before {
    content: ">";
    font-size: 18px;
    width: 0%;
    float: left;
    margin-left: -30px;
}

.deletion_marker_nonICC_Ohio:before,
.deletion_marker_nonICC_SC:before,
.deletion_marker_nonICC_CHI:before,
.deletion_marker_nonICC_OR:before,
.deletion_marker_nonICC_WA:before,
.deletion_marker_nonICC_IN:before,
.deletion_marker_nonICC_NY:before,
.deletion_marker_nonICC_NYC:before,
.deletion_marker_nonICC_MN:before,
.deletion_marker_nonICC_PHX:before,
.deletion_marker_nonICC_CB:before {
    content: ">";
    font-size: 18px;
    width: 0%;
    float: left;
    margin-left: -30px;
}

// State-specific rules will override this
td .deletion_marker_nonICC:before {
    content: ">";
    font-size: 26px;
    width: 0%;
    position: absolute;
    left: -20px;
    top: -5px;
}

td .deletion_marker_nonICC_Ohio:before,
td .deletion_marker_nonICC_SC:before,
td .deletion_marker_nonICC_CHI:before,
td .deletion_marker_nonICC_OR:before,
td .deletion_marker_nonICC_WA:before,
td .deletion_marker_nonICC_IN:before,
td .deletion_marker_nonICC_NY:before,
td .deletion_marker_nonICC_NYC:before,
td .deletion_marker_nonICC_MN:before,
td .deletion_marker_nonICC_PHX:before,
td .deletion_marker_nonICC_CB:before {
    content: ">";
    font-size: 26px;
    width: 0%;
    position: absolute;
    left: -20px;
    top: -5px;
}

.deletion_marker_nonICC_hollow:before,
td .deletion_marker_nonICC_hollow:before {
    content: "\21E8";
    font-size: 26px;
    width: 0%;
    left: -20px;
    top: -5px;
}

.deletion_marker_nonICC_AR {
    display: block;
}
.deletion_marker_nonICC_AR:before {
    content: url("../../images/arkansas-removed-section-stars.png");
    font-size: 26px;
    position: absolute;
    left: -30px;
}


/* END: Category specific global styles */
.max-content-container {
    max-width: 1024px;
    margin: 0 auto;
}

.newIccStyles {
    .chapter,
    .appendix,
    .index,
    .copyright_page,
    .frontmattercontent,
    .frontmatter,
    .v-card {
        h1 {
            color: black;
        }
        overflow-wrap: unset;
        color: #424242;
    }

    p {
        margin-top: 0.0px;
        margin-bottom: 8px;
        text-align: justify;
    }

    h2 > p, h3 > p, h4 > p, h5 > p, h6 > p {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    /* Cover */
    .cover {
        display: block;
        text-align: center;
        max-width: 100%;
        max-height: 100%;
    }

    /* Title Page */
    .title_page_text {
        font-weight: bold;
        font-style: normal;
        font-size: 9.35pt;
        text-align: left;
    }

    .title_page_acronym, .title_page_title {
        font-weight: bold;
        font-style: normal;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .title_page_acronym {
        font-size: 80px;
        text-align: left;
    }

    .title_page_title {
        font-size: 24px;
        text-align: center;
    }

    .title_page_subtitle {
        font-size: 11.9pt;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 1.0em;
    }

    .icc_logo {
        /*font-family: Arial, sans-serif;*/
        font-weight: normal;
        font-style: normal;
        text-align: right;
    }

    /* Copyright Page */
    .copyright_page {
        /*font-family: "Times New Roman", serif;*/
    }

    .copyright_statement {
        /*font-family: "Times New Roman", serif;*/
        font-weight: normal;
        font-style: normal;
        text-align: center;
    }


    /* Front Matter */
    .frontmatter {
        /*font-family: Arial, sans-serif;*/
    }

    .frontmatter_title {
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .frontmatter_subhead {
        font-size: 18px;
        /* text-align: center; */
        text-align: left; /* Added 08/22/16 Requested subheads be flush left */
        margin-bottom: 10px;
        font-weight: bold; /* Added 8/22/16 Requested subheads be bold */
    }

    /* Heading Style */
    h2.ordinance,
    h1.ordinance,
    h1.chapter,
    h1.appendix {
        font-weight: bold;
        font-style: normal;
        margin-bottom: 40px;
    }

    h1.ordinance {
        font-size: 16pt;
    }

    h2.ordinance {
        font-size: 12pt;
    }

    h1.part {
        font-weight: bold;
        font-style: italic;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 18px;
    }

    h1.chapter,
    h1.appendix {
        font-size: 24px;
    }

    h1 .ct {
        font-weight: bold;
        font-style: normal;
        text-align: center;
        font-size: 16pt;
    }

    h1.subchapter {
        font-weight: bold;
        font-style: normal;
        font-size: 10pt;
        /* text-align: left; */
        text-align: center; /* Added 08/22/16 Request all subchapter headings be centered */
        margin-top: 15px;
        margin-bottom: 10px;
    }

    section.level1 h1, section.level2 h2, section.level3 h3, section.level4 h4, section.level5 h5, section.level6 h6, section.level7 h6, section.level8 h6, section.level9 h6, section.level10 h6 {
        font-weight: bold;
        font-style: normal;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    section.level1 h1 {
        font-size: 14px;
        margin-top: 10px;
    }

    section.level2 h2 {
        text-align: left;
        font-size: 10pt;
    }

    section.level3 h3 {
        text-align: left;
        font-size: 10pt;
    }

    section.level4 h4 {
        font-size: 10pt;
        text-align: left;
    }

    section.level5 h5 {
        font-size: 10pt;
        text-align: left;
    }

    section.level6 h6 {
        text-align: left;
        font-size: 10pt;
    }

    section.level7 h6 {
        font-size: 10pt;
        text-align: left;
    }

    section.level8 h6 {
        font-size: 10pt;
        text-align: left;
    }

    section.level9 h6 {
        text-align: left;
        font-size: 10pt;
    }

    section.level10 h6 {
        font-size: 10pt;
        text-align: left;
    }

    /* Inline-Heading Style */
    .run_in {
        font-style: normal;
        text-align: left;
    }

    /* List Style */
    .no_mark {
        list-style-type: none;
        font-weight: normal;
        font-style: normal;
        text-align: left;
        margin-left: 10px;
    }

    ul {
        list-style-type: disc;
        font-weight: normal;
        font-style: normal;
        text-align: left;
        margin: 0;
        padding: 0;
    }

    .main_entries {
        margin-left: 20px;

        li {
            clear: both;
        }
    }

    .term {
        font-weight: bold;
        font-style: normal;
        text-align: left;
    }

    dl, dt, dd {
        font-weight: normal;
        font-style: normal;
    }

    dl {
        margin-top: 10px;
    }

    dt {
        font-size: 10pt;
        text-align: left;
        display: inline-block;
    }

    dd {
        text-align: left;
        display: inline-block;
    }

    dt.heading, dd.heading {
        font-weight: bold;
        font-style: normal;
        font-size: 10pt;
        text-align: left;
    }

    /**/
    dl.organization {
        font-weight: normal;
        font-style: normal;
        margin-top: 1.0em;
        margin-bottom: 1.0em;
    }

    /**/
    dl.organization dt {
        font-weight: bold;
        font-style: normal;
        font-size: 24pt;
        text-align: left;
    }

    /*Figure Style */

    figure.figure {
        /*font-family: "Times New Roman", serif;*/
        font-weight: normal;
        font-style: normal;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    /**/
    img {
        max-width: 100%;
        text-align: center;
    }

    figure img {
        font-style: normal;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
    }

    figure figcaption .figure_note {
        font-weight: normal;
        font-style: normal;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .note {
        /*	font-size: 8pt;*/
    }

    figure figcaption .SI {
        font-weight: normal;
        font-style: normal;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    figure figcaption .figure_number {
        font-weight: bold;
        font-style: normal;
        text-align: center;
        margin: 10px 5px 0 5px;
    }

    figure figcaption .figure_title {
        font-weight: bold;
        font-style: normal;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 10px;
    }

    figure figcaption .SI .run_in {
        text-align: left;
    }

    /* Table Style */
    figure.table {
        font-weight: normal;
        font-style: normal;
        margin-top: 10px;
        margin-bottom: 10px;
        overflow: auto;
        overflow-y: hidden;
    }

    /** part of newIccStyles */
    figure figcaption .table_number {
        font-weight: bold;
        font-style: normal;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 8px;
    }

    figure figcaption .table_title {
        font-weight: bold;
        font-style: normal;
        text-align: left;
        margin-top: 0;
        margin-bottom: 10px;
        margin-left: 0;
    }

    figure.table img {
        font-weight: normal;
        font-style: normal;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
    }

    table, table thead th, table tbody, table tbody td {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
    }

    table {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-collapse: collapse;
    }

    figure {
        table {
            vertical-align: middle;

            td {
                vertical-align: middle;
                border-collapse: collapse;
            }
        }
    }

    table thead th {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 2px;
        padding-left: 20px;
    }

    table tbody {
        font-size: inherit;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    table tbody td {
        margin-top: 1.0em;
        margin-bottom: 1.0em;
        padding: 0.2em;
        position: relative;
        padding-left: 20px;
    }

    figure figcaption .table_note {
        font-weight: normal;
        font-style: normal;
        font-size: inherit;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    th.box_table, td.box_table {
        border: solid 1px;
        padding: 0.2em;
    }

    .hside_table {
        border-bottom: solid 1px;
        border-top: solid 1px;
        padding: 0.2em;
    }

    figure.table .section-action-wrapper {
        overflow-x: auto !important;
    }

    /* Equation Style */
    .equation {
        font-weight: normal;
        font-style: normal;
        margin-top: 10px;
        margin-bottom: 5px;
        overflow: hidden;
    }

    /* Quotes Style */
    .exception {
        font-weight: normal;
        font-style: normal;
        text-align: left;
        margin-left: 30px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .exception[data-changed="changed_level0"] {
        color: blue;
    }

    /* Link Style */
    .table_reference, .figure_reference, .section_reference, .equation_reference {
        font-weight: normal;
        font-style: normal;
        text-align: left;
    }

    /* Back Matter */
    .backmatter {
        /*font-family: Arial, sans-serif;*/
    }

    .backmatter_title {
        font-weight: bold;
        font-style: normal;
        font-size: inherit;
        text-align: center;
        margin-top: 3.0em;
        margin-bottom: 2.0em;
    }

    .editorial_changes {
        font-family: Arial, sans-serif;
    }

    h1.editorial_changes {
        font-weight: bold;
        font-style: normal;
        text-align: left;
        font-size: 10pt;
        margin-top: 0.5em;
        margin-bottom: 0px;
    }

    section.ind_alpha h2 {
        font-weight: bold;
        font-style: normal;
        font-size: 12pt;
        text-align: center;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    .main {
        font-weight: normal;
        font-style: normal;
        font-size: 10pt;
        text-align: left;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 1.0em;
        text-indent: -1.0em;
    }

    .sub {
        font-weight: normal;
        font-style: normal;
        font-size: 10pt;
        text-align: left;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 2.0em;
        text-indent: -1.0em;
    }

    /* Font Style */
    .center {
        text-align: center;
    }

    .right {
        text-align: right;
    }

    .left {
        text-align: left !important;
        float: none !important;
    }

    .justify {
        text-align: justify;
    }

    .small {
        font-size: 80%;
    }

    .write_on_line {
        text-decoration: underline;
    }

    /* Para Style */
    .reverse_indent {
        font-size: 10pt;
        margin-left: 1.0em;
        text-indent: -1.0em;
    }

    /* Floating Style */
    .float_right {
        display: block;
    }

    .float_left {
        display: block;
    }

    /* Margin Style */

    .margin_level1 {
        margin-left: 1.0em;
    }

    .margin_level2 {
        margin-left: 2.0em;
    }

    .margin_level3 {
        margin-left: 3.0em;
    }

    .margin_level4 {
        margin-left: 4.0em;
    }

    .margin_level5 {
        margin-left: 5.0em;
    }

    .margin_level6 {
        margin-left: 6.0em;
    }

    .margin_level7 {
        margin-left: 7.0em;
    }

    .margin_level8 {
        margin-left: 8.0em;
    }

    .margin_level9 {
        margin-left: 9.0em;
    }

    .margin_level10 {
        margin-left: 10.0em;
    }

    /* border Style */
    .border_left, .border_right, .border_top, .border_bottom {
        border-right: 0px;
        border-left: 0px;
    }

    .border_right {
        border-right: solid 1px #CCCCCC !important;
    }

    .border_left {
        border-left: solid 1px #CCCCCC !important;
    }

    .border_top {
        border-top: solid 1px #CCCCCC !important;
    }

    .border_bottom {
        border-bottom: solid 1px #CCCCCC !important;
    }

    /* Other Style */
    .break {
        page-break-before: always;
    }

    .clear {
        clear: both;
    }

    .referenced_standards {
        font-family: Arial, sans-serif;
        overflow: hidden;
    }

    .changed {
        font-weight: normal;
        font-style: normal;
        color: rgb(130, 15, 30);
    }

    .formal_usage {
        font-style: italic;
    }

    th .formal_usage {
        font-style: italic;
    }

    .fraction {
        font-size: 15px;
    }

    .valign_bottom {
        vertical-align: bottom;
    }

    .valign_top {
        vertical-align: top;
    }

    .valign_middle {
        vertical-align: middle;
    }

    .very_small {
        font-size: 0.3em;
    }

    p.table_number > a:hover,
    p.figure_number > a:hover,
    h2 > a:hover,
    h3 > a:hover,
    h4 > a:hover,
    h5 > a:hover,
    h6 > a:hover {
        background-color: yellow;
    }

    p.table_number > a:link,
    p.figure_number > a:link,
    h2 > a:link,
    h3 > a:link,
    h4 > a:link,
    h5 > a:link,
    h6 > a:link {
        text-decoration: none;
        color: inherit;
    }

    .dual_columns {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .column1, .column2 {
        text-align: left;
    }

    .dual_columns span.column1 {
        float: none;
    }

    .iccpub {
        font-style: italic;
    }

    .changed_ICC {
        color: blue;
    }

    a {
        color: #337AB7;
    }

    a:visited {
        color: #8D3EB7;
    }

    a:hover, a:focus {
        color: #14E38D;
    }

    a:active {
        color: #0AFBFF;
    }

    section.level2 h1 {
        text-align: left;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 8px;
    }

    .jurisdiction_insert {
        font-weight: bold;
    }

    section span.label {
        color: inherit;
    }

    section.level1 span.label, section.level1 h1.level1, h1.subchapter {
        font-size: 22px;
        overflow: hidden;
    }


    section.level2 span.label {
        font-size: 14px;
    }

    h1 span.label {
        font-size: 24px;
    }

    li.toc_level_1 span.label {
        font-size: inherit;
    }

    div.help {
        font-style: italic;
        text-align: center;
    }

    span.label {
        padding-left: 0px;
    }

    .equation_number {
        display: inline-block;
        text-align: right;
        float: right;
    }

    div.list ol.no-mark li p span.label {
        font-weight: normal;
    }

    ol.no_mark span.label {
        font-size: 14px;
        font-weight: normal;
        margin: 0 5px;
    }

    .changed_ICC table, .changed_ICC table td, .changed_ICC table tr, .changed_ICC table tr td {
        border-color: blue;
        color: blue;
    }

    .dual_columns p, .no_mark p {
        margin-bottom: 2px;
    }

    section .list dt, section .list dd {
        width: 40%;
        float: none;
    }

    section .list dd {
        width: 60%;
    }

    section .list dt label {
        width: 100%;
    }

    .frontmatter_subhead + .list dt {
        width: 40%;
        float: none;
    }

    p.equation span.run_in {
        padding-right: 5px;
    }

    div.term_group span.label {
        font-size: 14px;
    }

    p.sub_term {
        margin-left: 15px;
    }

    span.definition {
        display: block;
    }

    ol.no_mark p.sub_term span.label {
        font-weight: bold;
    }

    ol.no_mark {
        padding-left: 10px !important;
    }

    div.list dt label {
        font-weight: normal;
        margin-bottom: 0px;
        margin-top: 10px;
    }

    .italic {
        span.formal_usage:first-child:after {
            content: " ";
        }

        font-style: italic;
    }

    .bold {
        font-weight: bold;
    }


    blockquote {
        border: none !important;
        font-size: 14px !important;
        margin: 0 0 20px !important;
        padding: 10px 20px !important;
        border-left: 0px solid #eee;
    }

    span.chapter_number {
        padding-right: 5px;
    }

    /* Bookmark and print this secttion */

    div.print-section a {
        text-align: center;
        color: #818181;
        text-decoration: none;
        font-size: 0.8em;
    }

    div.print-section a.disabled {
        display: none;
    }

    .print-link {
        color: #818181;
    }

    .print-link.disabled {
        cursor: text;
        text-decoration: none;
    }

    /* Deletion marker */

    .deletion_marker, .deletion_mark {
        font-size: 26px;
        height: 18px;
    }

    .td_deletion {
        width: 55%;
        position: absolute;
        margin-left: -2.3%;
    }

    .index_link {
        float: right;
        text-align: right;
        width: 62%;
    }

    .index .sub_entries > li {
        width: 100%;
    }

    .main_entries > li .index_term {
        font-weight: bold;
        width: 200px;
        cursor: text;
    }

    /* kept for ref */
    /*
    ul.main_entries li {
        width: 100%;
        float: left;
    }
    */

    .sub_entries > li .index_term {
        font-weight: normal;
    }

    section.level3 h1,
    section.level4 h1,
    section.level5 h1,
    section.level6 h1,
    section.level7 h1,
    section.level8 h1,
    section.level9 h1,
    section.level10 h1 {
        font-size: 18px;
        font-weight: bold;
    }

    /* To give space in part number & text */
    span.part_number {
        padding-right: 5px;
    }

    .non_iccpub {
        font-style: italic !important;
    }

    .informal th {
        text-align: center;
    }

    /* New CSS Classes for Indore Team */

    .content_centered, .content_center {
        text-align: center;
        width: 100%;
        display: table;
    }

    .content_left {
        float: left;
        text-align: left;
        width: 100%;
        margin-bottom: 10px;
    }

    .content_right {
        float: left;
        text-align: right;
        width: 100%;
    }

    .content_newline {
        white-space: nowrap;
        width: 100%;
        float: left;
    }

    .content_newline_inside_td {
        white-space: nowrap;
        width: 100%;
        display: list-item;
        list-style: none;
    }

    .content_indent_first {
        text-indent: 25px;
    }

    .content_underline {
        text-decoration: underline;
    }

    .content_indent_full {
        margin-left: 1em;
        display: block;
    }

    .content_inline {
        display: inline !important;
    }

    .level1 .level1_title > div.left {
        text-align: center !important;
        width: 100%;
    }

    .Print_statement {
        text-align: center;
    }

    .cell_text_vtop {
        vertical-align: top;
    }

    .cell_text_vmiddle {
        vertical-align: middle;
    }


    .cell_text_vbottom {
        vertical-align: bottom;
    }

    /* CSS added in Phase 2 for XML clean task : Start */

    .Print_statement {
        text-align: center;
        font-style: normal;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .ICC_logo {
        /*font-family: Arial, sans-serif;*/
        font-weight: normal;
        font-style: normal;
        text-align: right;
    }

    .isbn_statement, .printing_history, .copyright_page_title, .publication_history, .copyright_statement {
        text-align: center;
    }

    figure img {
        font-style: normal;
        max-width: 100%;
        text-align: center;
        display: block;
        margin: 0 auto;
    }

    .float_text_left {
        float: left;
    }

    .deletion_marker_paragraph {
        font-size: 26px;
        height: 18px;
        background-repeat: no-repeat;
        margin-left: -15px;
        margin-top: 10px;
        margin-bottom: 0;
        display: -webkit-inline-box;
        width: 25px;
    }

    .deletion_marker {
        font-size: 26px;
        height: 18px;
        //width: 20px;
        width: 0 !important;
        margin-left: 2px;
        margin-right: 5px;
        margin-top: -10px;
        margin-bottom: -10px;
        display: -webkit-inline-box;
        border: none !important;
        font-weight: bold;
        &.in-list {
            width: 0px;
            margin-left: 0px;
        }
    }

    .hex-deletion-marker-table-margin {
        margin-left: 30px;
    }
    .hex-deletion-marker-table {
        position: absolute;
        left: -32px;
        margin-top: 2px;
    }
    .hex-deletion-marker-table-note {
        position: absolute;
        left: 8px;
        margin-top: -4px;
        font-size: 18px !important;
    }
    .hex-deletion-def-item {
        position: absolute;
        left: -32px;
        margin-top: -8px;
    }
    .hex-deletion-marker-paragraph-text {
        position: absolute;
        left: 10px;
        margin-top: 2px;
    }
    .hex-deletion-marker-div-text {
        position: absolute;
        left: -30px;
        margin-top: -5px;
    }
    .hex-deletion-marker-list-text {
        position: absolute;
        left: -30px;
        margin-top: -7px;
    }
    .hex-deletion-marker-table-blank {
        margin-top: -10px;
    }
    .hex-deletion-marker {
        font-size: 26px;
        line-height: 18px;
        font-weight: bold;
        color: #000000!important;
    }

    .deletion_marker_table {
        font-size: 26px;
        height: 18px;
        margin-left: 20px;
        margin-top: -10px;
        margin-bottom: -10px;
    }

    .relocated_to:before {
        content: '* ';
    }
    .new_location:before {
        content: '** ';
    }

    .deletion_marker_row_position {
        position: relative;
        width: 20px;
        font-size: 26px;
        font-weight: bold;
    }

    .bottom_deletion_marker {
        position: absolute;
        bottom: 8px;
        right: 2px;
    }

    aside.footnote {
        font-size: 12px;
    }

    .promulgator > table {
        clear: both;
    }

    /* Styling table and figure footnotes with appropriate font size */
    .figure_note .note,
    .figure_note .note .label,
    .table_notes .figure_note_SI,
    .table_notes .figure_note {
        font-size: 12px;
        margin-bottom: 0;
    }

    .bibliography {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }

    .heading {
        font-weight: bold;
        text-align: center;
    }

    .sub_term {
        margin-left: 30px;
    }

    .dual_columns {
        margin-left: 30px;
    }

    .equation_group {
        text-align: center;
    }

    /* CSS added for indentation issue */
    .list ul.no_mark {
        list-style-type: none;
        font-weight: normal;
        font-style: normal;
        text-align: left;
        margin-left: 10px;
        padding-left: 20px;
    }

    /* CSS added for indentation issue */
    /* CA book css issues */
    .chapter_subtitle {
        font-weight: bold;
        text-align: center;

    }

    .adopting_agency {
        font-weight: bold;
    }

    .deletion_marker_nonICC_para:before {
        content: ">";
        font-size: 18px;
        width: 0%;
        float: left;
        margin-left: -20px;
        color: black;
    }

    /* CA book css issues */

    /* Other Css realted issues */
    .table-border {
        border: 1px solid #fff;
    }

    .table-border tbody {
        border-top: none;
    }

    .deleted_ICC {
        display: none;
    }

    .deletion_marker_nonICC_CH:before,
    .deletion_marker_nonICC_OG:before,
    .deletion_marker_nonICC_RI:before {
        content: ">";
        font-size: 18px;
        width: 0%;
        float: left;
        margin-left: -30px;
    }

    td .deletion_marker_nonICC_CH:before,
    td .deletion_marker_nonICC_OG:before,
    td .deletion_marker_nonICC_RI:before {
        content: ">";
        font-size: 26px;
        width: 0%;
        position: absolute;
        left: -20px;
        top: -5px;
    }

    .outside_border_content {
        border: solid 1px;
        padding: 0.2em;
    }

    ins[data-changed="changed_level0"], span[section-number-type="new"], .reference_standard[data-changed="changed_level0"], p[section-number-type="new"] {
        color: blue;
        text-decoration: none;
    }

    .frontmatter {
        ins[data-changed="changed_level0"] {
            color: inherit;
        }
    }

    ins[data-changed="changed_level1"] {
        color: red;
        text-decoration: none;
    }

    .main_term .label {
        font-weight: bold;
        padding-right: 3px;
    }
    .box {
        background-color: #DDCEA7;
    }
}

.newIccStylesCategorySpecific {
    p {
        margin-top: 0.0px;
        margin-bottom: 8px;
        text-align: justify;
    }

    h2 > p, h3 > p, h4 > p, h5 > p, h6 > p {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    /* Cover */
    .cover {
        display: block;
        text-align: center;
        max-width: 100%;
        max-height: 100%;
    }


    /* Title Page */
    .title_page_text {
        font-weight: bold;
        font-style: unset;
        font-size: 9.35pt;
        text-align: left;
    }

    .title_page_acronym, .title_page_title {
        font-weight: bold;
        font-style: unset;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .title_page_acronym {
        font-size: 80px;
        text-align: left;
    }

    .title_page_title {
        font-size: 24px;
        text-align: center;
    }

    .title_page_subtitle {
        font-size: 11.9pt;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 1.0em;
    }

    .icc_logo {
        /*font-family: Arial, sans-serif;*/
        font-weight: normal;
        font-style: unset;
        text-align: right;
    }

    /* Copyright Page */
    .copyright_page {
        /*font-family: "Times New Roman", serif;*/
    }

    .copyright_statement {
        /*font-family: "Times New Roman", serif;*/
        font-weight: normal;
        font-style: unset;
        text-align: center;
    }


    /* Front Matter */
    .frontmatter {
        /*font-family: Arial, sans-serif;*/
    }

    .frontmatter_title {
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .frontmatter_subhead {
        font-size: 18px;
        /* text-align: center; */
        text-align: left; /* Added 08/22/16 Requested subheads be flush left */
        margin-bottom: 10px;
        font-weight: bold; /* Added 8/22/16 Requested subheads be bold */
    }

    section.ordinance,
    section.part,
    section.chapter,
    section.subchapter,
    section.level1,
    section.level2,
    section.level3,
    section.level4,
    section.level5 {
        /*font-family: "Times New Roman", serif;*/
        /*font-family:  "Helvetica Neue",Helvetica,Arial,sans-serif*/
    }

    section.level3 {
        margin-left: 30px !important;
    }

    section.level4 {
        margin-left: 35px !important;
    }

    section.level5 {
        margin-left: 40px !important;
    }

    section.level6 {
        margin-left: 45px !important;
    }

    section.level7 {
        margin-left: 50px !important;
    }

    section.level8 {
        margin-left: 55px !important;
    }

    section.level9 {
        margin-left: 60px !important;
    }

    section.level10 {
        margin-left: 65px !important;
    }

    /* Heading Style */
    h2.ordinance, h1.ordinance, h1.chapter, h1.appendix {
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 20px;
        /*    font-style: unset;*/
    }

    h1.ordinance {
        font-size: 16pt;
    }

    h2.ordinance {
        font-size: 12pt;
    }

    h1.part {
        font-weight: bold;
        font-style: italic;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 18px;
    }

    h1.chapter, h1.appendix {
        font-size: 24px;
    }

    h1 .ct {
        font-weight: bold;
        font-style: unset;
        text-align: center;
        font-size: 16pt;
    }

    h1.subchapter {
        font-weight: bold;
        font-style: unset;
        font-size: 10pt;
        /* text-align: left; */
        text-align: center; /* Added 08/22/16 Request all subchapter headings be centered */
        margin-top: 15px;
        margin-bottom: 10px;
    }

    /* Inline-Heading Style */
    .run_in {
        /*font-weight: bold;*/
        text-align: left;
        font-style: unset;
    }

    section.level1 h1, section.level2 h2, section.level3 h3, section.level4 h4, section.level5 h5, section.level6 h6, section.level7 h6, section.level8 h6, section.level9 h6, section.level10 h6 {
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: 5px;
        font-style: unset;
    }

    section.level1 h1 {
        font-size: 14px;
        margin-top: 10px;
    }

    section.level2 h2 {
        text-align: left;
        font-size: 10pt;
    }

    section.level3 h3 {
        text-align: left;
        font-size: 10pt;
    }

    section.level4 h4 {
        font-size: 10pt;
        text-align: left;
    }

    section.level5 h5 {
        font-size: 10pt;
        text-align: left;
    }

    section.level6 h6 {
        text-align: left;
        font-size: 10pt;
    }

    section.level7 h6 {
        font-size: 10pt;
        text-align: left;
    }

    section.level8 h6 {
        font-size: 10pt;
        text-align: left;
    }

    section.level9 h6 {
        text-align: left;
        font-size: 10pt;
    }

    section.level10 h6 {
        font-size: 10pt;
        text-align: left;
    }


    ul {
        list-style-type: disc;
        font-weight: normal;
        text-align: left;
        font-style: unset;
    }

    .label {
        /*	font-family: "Times New Roman", serif;
                font-weight: normal;
                font-style: normal;
                font-size: 10pt;
                text-align: left;
        */
    }

    .definitions {
        /*font-family: "Times New Roman", serif;*/
    }

    .continued_definition {
        text-indent: 25px;
    }

    .term {
        /*font-family: "Times New Roman",serif;*/
        font-weight: bold;
        font-size: 14px;
        text-align: left;
        font-style: unset;
    }

    dl, dt, dd {
        font-weight: normal;
        font-style: unset;
    }

    dl {
        margin-top: 10px;
    }

    dt {
        font-size: 10pt;
        text-align: left;
        display: inline-block;
    }

    dd {
        text-align: left;
        display: inline-block;
    }

    dt.heading, dd.heading {
        font-weight: bold;
        font-style: unset;
        font-size: 10pt;
        text-align: left;
    }

    /**/
    dl.organization {
        font-weight: normal;
        font-style: unset;
        margin-top: 1.0em;
        margin-bottom: 1.0em;
    }

    /**/
    dl.organization dt {
        /*font-family: dvsserif, "Times New Roman", serif;*/
        font-weight: bold;
        font-style: unset;
        font-size: 24pt;
        text-align: left;
    }

    /*dl.organization dd {*/
    /*	font-family: dvsserif, "Times New Roman", serif;
            font-weight: normal;
            font-style: normal;
            font-size: 8pt;
            text-align: left;
            }
    */

    /*Figure Style */

    figure.figure {
        /*font-family: "Times New Roman", serif;*/
        font-weight: normal;
        font-style: unset;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    /**/
    img {
        max-width: 100%;
        text-align: center;
    }

    figure img {
        /*font-family: "Times New Roman", serif;*/
        /*font-weight: normal;*/
        font-style: unset;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
    }

    figure figcaption div.figure_notes {
        /*font-family: "Times New Roman", serif;*/
    }

    figure figcaption .figure_note {
        /*font-family: "Times New Roman", serif;*/
        font-weight: normal;
        font-style: unset;
        /*font-size: 8pt;*/
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .note {
        /*	font-size: 8pt;*/
    }

    figure figcaption .SI {
        /*font-family: "Times New Roman", serif;*/
        font-weight: normal;
        font-style: normal;
        /*font-size: 8pt;*/
        margin-top: 0px;
        margin-bottom: 0px;
    }

    figure figcaption .figure_number {
        /*font-family: "Arial Bold", sans-serif;*/
        font-weight: bold;
        font-style: unset;
        text-align: center;
        /*font-size: 8pt;*/
        margin-top: 10px;
        margin-bottom: 0px;
    }

    figure figcaption .figure_title {
        /*font-family: "Arial Bold", sans-serif;*/
        font-weight: bold;
        font-style: unset;
        text-align: center;
        /*font-size: 8pt;*/
        margin-top: 0px;
        margin-bottom: 10px;
    }

    figure figcaption .SI .run_in {
        /*font-family: "Times New Roman", serif;*/
        /*font-weight: normal;
        font-style: normal;
        *//*font-size: 8pt;*/
        text-align: left;
    }


    /* Table Style */
    figure.table {
        font-weight: normal;
        margin-top: 10px;
        margin-bottom: 10px;
        /*    font-style: unset;*/
    }

    figure figcaption .table_number {
        font-weight: bold;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 0px;
        font-style: unset;
    }

    figure figcaption .table_title {
        font-weight: bold;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 10px;
        margin-left: 5px;
        font-style: unset;
    }

    figure.table img {
        font-weight: normal;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        font-style: unset;
    }

    table, table thead th, table tbody, table tbody td {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
    }

    table {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-collapse: collapse;
    }


    table thead th {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 2px;
    }

    table tbody {
        font-size: inherit;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    table tbody td {
        margin-top: 1.0em;
        margin-bottom: 1.0em;
        padding: 0.2em;
        position: relative;
    }

    figure figcaption div.table_notes {
        /*font-family: "Times New Roman", serif;*/
    }

    figure figcaption .table_note {
        font-weight: normal;
        font-size: inherit;
        margin-top: 0px;
        margin-bottom: 0px;
        font-style: unset;
    }

    th.box_table, td.box_table {
        border: solid 1px;
        padding: 0.2em;
    }

    .hside_table {
        border-bottom: solid 1px;
        border-top: solid 1px;
        padding: 0.2em;
    }


    /* Equation Style */
    .equation {
        font-weight: normal;
        font-style: unset;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    /* Quotes Style */
    .exception {
        font-weight: normal;
        text-align: left;
        margin-left: 30px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-style: unset;
    }


    /* Link Style */
    .table_reference, .figure_reference, .section_reference, .equation_reference {
        font-weight: normal;
        text-align: left;
        font-style: unset;
    }

    /* Back Matter */
    .backmatter {
        /*font-family: Arial, sans-serif;*/
    }

    .backmatter_title {
        font-weight: bold;
        font-style: normal;
        font-size: inherit;
        text-align: center;
        margin-top: 3.0em;
        margin-bottom: 2.0em;
    }

    .editorial_changes {
        font-family: Arial, sans-serif;
    }

    h1.editorial_changes {
        font-weight: bold;
        font-style: unset;
        text-align: left;
        font-size: 10pt;
        margin-top: 0.5em;
        margin-bottom: 0px;
    }


    /* Index Style */
    .index {
        /*font-family: Arial, sans-serif;*/
    }

    section.ind_alpha {
        /*font-family: Arial, sans-serif;*/
    }

    section.ind_alpha h2 {
        font-weight: bold;
        font-style: unset;
        font-size: 12pt;
        text-align: center;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    div.entry {
        /*font-family: Arial, sans-serif;*/
    }

    .main {
        /*font-family: Arial, sans-serif;*/
        font-weight: normal;
        font-style: unset;
        font-size: 10pt;
        text-align: left;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 1.0em;
        text-indent: -1.0em;
    }

    .sub {
        /*font-family: Arial, sans-serif;*/
        font-weight: normal;
        font-style: unset;
        font-size: 10pt;
        text-align: left;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 2.0em;
        text-indent: -1.0em;
    }


    /* Font Style */
    .center {
        text-align: center;
    }

    .right {
        text-align: right;
    }

    .left {
        text-align: left;
    }

    .justify {
        text-align: justify;
    }

    .small {
        font-size: 80%;
    }

    .write_on_line {
        text-decoration: underline;
    }

    /* Para Style */
    .reverse_indent {
        /*font-family: "Times New Roman", serif;*/
        font-size: 10pt;
        margin-left: 1.0em;
        text-indent: -1.0em;
    }

    /* Floating Style */
    .float_right {
        display: block;
    }

    .float_left {
        display: block;
    }

    /* Margin Style */

    .margin_level1 {
        margin-left: 1.0em;
    }

    .margin_level2 {
        margin-left: 2.0em;
    }

    .margin_level3 {
        margin-left: 3.0em;
    }

    .margin_level4 {
        margin-left: 4.0em;
    }

    .margin_level5 {
        margin-left: 5.0em;
    }

    .margin_level6 {
        margin-left: 6.0em;
    }

    .margin_level7 {
        margin-left: 7.0em;
    }

    .margin_level8 {
        margin-left: 8.0em;
    }

    .margin_level9 {
        margin-left: 9.0em;
    }

    .margin_level10 {
        margin-left: 10.0em;
    }

    /* border Style */
    .border_left, .border_right, .border_top, .border_bottom {
        border-right: 0px;
        border-left: 0px;
    }

    .border_right {
        border-right: solid 1px !important;
    }

    .border_left {
        border-left: solid 1px !important;
    }

    .border_top {
        border-top: solid 1px !important;
    }

    .border_bottom {
        border-bottom: solid 1px !important;
    }

    /* Other Style */
    .break {
        page-break-before: always;
    }

    .clear {
        clear: both;
    }

    .referenced_standards {
        font-family: Arial, sans-serif;
    }

    .changed {
        font-weight: normal;
        font-style: unset;
        color: rgb(130, 15, 30);
    }

    .formal_usage {
        font-style: italic;
    }

    th .formal_usage {
        /*font-family: "Arial Italic", sans-serif;*/
        font-style: italic;
    }

    .fraction {
        font-size: 15px;
    }

    .valign_bottom {
        vertical-align: bottom;
    }

    .valign_top {
        vertical-align: top;
    }

    .valign_middle {
        vertical-align: middle;
    }

    .very_small {
        font-size: 0.3em;
    }

    p.table_number > a:hover,
    p.figure_number > a:hover,
    h2 > a:hover,
    h3 > a:hover,
    h4 > a:hover,
    h5 > a:hover,
    h6 > a:hover {
        background-color: yellow;
    }

    p.table_number > a:link,
    p.figure_number > a:link,
    h2 > a:link,
    h3 > a:link,
    h4 > a:link,
    h5 > a:link,
    h6 > a:link {
        text-decoration: none;
        color: inherit;
    }

    .dual_columns {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .column1, .column2 {
        text-align: left;
    }

    .dual_columns span.column1 {
        /* width: 40px; */
        /*    float: left;*/
        float: none;
    }

    .iccpub {
        font-style: italic;
    }

    .changed_ICC {
        color: blue;
        border-color: black;
    }

    a {
        text-decoration: none;
    }

    section.level2 h1 {
        text-align: left;
        font-size: 14px;
        font-weight: bold;
    }

    .jurisdiction_insert {
        font-weight: bold;
    }

    section span.label {
        color: inherit;
    }

    section.level1 span.label, section.level1 h1.level1, h1.subchapter {
        font-size: 22px;
    }


    section.level2 span.label {
        font-size: 14px;
    }

    h1 span.label {
        font-size: 24px;
    }

    li.toc_level_1 span.label {
        font-size: inherit;
    }

    div.help {
        font-style: italic;
        text-align: center;
    }

    span.label {
        padding-left: 0px;
    }

    .equation_number {
        display: inline-block;
        text-align: right;
        float: right;
    }

    div.list ol.no-mark li p span.label {
        font-weight: normal;
    }


    /*-----------*/
    /*.promulgator p:first-child{*/
    .promulgator_acronym {
        width: 22%;
        float: left;
        font-size: 40px !important;
        font-weight: bold !important;
    }

    .referenced_standards {
        width: 100%;
        height: auto;
        display: table;
    }

    /*.referenced_standards:nth-child(2n){*/
    .referenced_standards:last-child {
        border-bottom: 1px solid #000;
        border-top: none;
        margin-bottom: 30px;
    }

    .referenced_standards:first-child {
        border-top: 1px solid #000;
    }

    .reference_standard_number {
        width: 20%;
        float: left;
        word-break: break-all;
    }

    /*.referenced_standards p + p{*/
    .reference_standard_title {
        width: 38%;
        float: left;
        padding: 0px 2%;
        box-sizing: border-box;
    }

    .sections_referencing_standard {
        width: 40%;
        text-align: right;
        float: left;
    }

    .promulgator .promulgator_contact_info {
        width: 30%;
        float: left;
        font-weight: bold;
    }

    .promulgator .promulgator_contact_info .promulgator_name, .promulgator .promulgator_contact_info .street_address {
        width: 100%;
        float: left;
    }

    span.section_referencing_standard:after {
        content: ", ";
    }

    span.section_referencing_standard:last-child:after {
        content: "";
    }

    span.city:after {
        content: ", ";
    }

    span.state:after {
        content: " ";
    }

    /* Rakesh Nainani*/
    ol.no_mark span.label {
        font-size: 14px;
        font-weight: normal;
        margin: 0 5px;
    }

    .changed_ICC table, .changed_ICC table td, .changed_ICC table tr, .changed_ICC table tr td {
        border-color: blue;
        color: blue;
    }

    .dual_columns p, .no_mark p {
        margin-bottom: 2px;
    }

    section .list dt, section .list dd {
        width: 40%;
        float: none;
    }

    section .list dd {
        width: 60%;
    }

    section .list dt label {
        width: 100%;
    }

    .frontmatter_subhead + .list dt {
        width: 40%;
        float: none;
    }

    p.equation span.run_in {
        padding-right: 5px;
    }

    div.term_group span.label {
        font-size: 14px;
    }

    p.sub_term {
        margin-left: 15px;
    }

    span.definition {
        display: block;
    }

    ol.no_mark p.sub_term span.label {
        font-weight: bold;
    }

    ol.no_mark {
        padding-left: 10px !important;
    }

    div.list dt label {
        font-weight: normal;
        margin-bottom: 0px;
        margin-top: 10px;
    }


    .italic {
        font-style: italic;
    }

    .bold {
        font-weight: bold;
    }


    blockquote {
        /*color: #404040;
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;*/
        border: none !important;
        font-size: 14px !important;
        margin: 0 0 10px !important;
        padding: 5px 20px !important;
        border-left: 0px solid #eee; /* Remove 5px left gray border 08/22/16 / AB */
    }

    span.chapter_number {
        padding-right: 5px;
    }


    /* Bookmark and print this secttion */

    div.print-section a {
        text-align: center;
        color: #818181;
        /*display: block;*/
        text-decoration: none;
        font-size: 0.8em;
    }

    div.print-section a.disabled {
        display: none;
    }

    .print-link {
        color: #818181;
    }

    .print-link.disabled {
        cursor: text;
        text-decoration: none;
    }

    /* Deletion marker */
    .deletion_marker, .deletion_mark {
        font-size: 26px;
        background-size: 2% auto;
        height: 18px;
        background-repeat: no-repeat;
    }

    .td_deletion {
        width: 55%;
        position: absolute;
        margin-left: -2.3%;
    }

    .relocated_to::before {
        content: "*Relocated To New Location ";
        color: blue !important;
    }

    .new_location::before {
        content: "**Relocated From Other Location ";
        color: blue !important;
    }

    .index_link {
        float: right;
        //width: 75%;
        text-align: right;
    }

    .index .sub_entries > li {
        //float: left;
        width: 100%;
    }

    .main_entries > li .index_term {
        font-weight: bold;
    }

    .sub_entries > li .index_term {
        font-weight: normal;
    }

    section.level3 h1,
    section.level4 h1,
    section.level5 h1,
    section.level6 h1,
    section.level7 h1,
    section.level8 h1,
    section.level9 h1,
    section.level10 h1 {
        font-size: 18px;
        font-weight: bold;
    }

    // To give space in part number & text
    span.part_number {
        padding-right: 5px;
    }

    .non_iccpub {
        font-style: italic !important;
    }

    .informal th {
        text-align: center;
    }


    /* New CSS Classes for Indore Team */

    .content_centered, .content_center {
        text-align: center;
        width: 100%;
        display: table;
    }


    .content_left {
        float: left;
        text-align: left;
        width: 100%;
        margin-bottom: 10px;
    }

    .content_right {
        float: left;
        text-align: right;
        width: 100%;
    }

    .content_newline {
        white-space: nowrap;
        width: 100%;
        float: left;
    }

    .content_newline_inside_td {
        white-space: nowrap;
        width: 100%;
        display: list-item;
        list-style: none;
    }

    .content_indent_first {
        text-indent: 20px;
    }

    .content_underline {
        text-decoration: underline;
    }

    .content_indent_full {
        margin-left: 1em;
        display: block;
    }

    .content_inline {
        display: inline !important;
    }

    .level1 .level1_title > div.left {
        text-align: center !important;
        width: 100%;
    }

    /*New changes for FRONTMATTER*/
    .Print_statement {
        text-align: center;
    }

    .cell_text_vtop {
        vertical-align: top;
    }

    .cell_text_vmiddle {
        vertical-align: middle;
    }


    .cell_text_vbottom {
        vertical-align: bottom;
    }

    /* CSS added in Phase 2 for XML clean task : Start */

    .Print_statement {
        text-align: center;
        font-style: unset;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .ICC_logo {
        /*font-family: Arial, sans-serif;*/
        font-weight: normal;
        font-style: unset;
        text-align: right;
    }


    .isbn_statement, .printing_history, .copyright_page_title, .publication_history, .copyright_statement {
        text-align: center;
    }

    figure img {
        /*font-family: "Times New Roman", serif;*/
        /*font-weight: normal;*/
        font-style: unset;
        margin-top: 10px;
        max-width: 100%;
        text-align: center;
        display: block;
        margin: 0 auto;
    }

    .float_text_left {
        float: left;
    }

    .deletion_marker {
        font-size: 26px;
        height: 18px;
        margin-left: -15px;
        margin-top: -10px;
        margin-bottom: -10px;
    }

    .deletion_marker_table {
        height: 18px;
        font-size: 26px;
        margin-left: 20px;
        margin-top: -10px;
        margin-bottom: -10px;
    }

    .deletion_marker_row_position {
        position: relative;
        width: 20px;
        font-size: 26px;
        font-weight: bold;
    }

    .bottom_deletion_marker {
        position: absolute;
        bottom: 8px;
        right: 2px;

    }

    aside.footnote {
        font-size: 12px;
    }

    .promulgator > table {
        clear: both;
    }

    .list {
        margin-left: 1em;
    }

    /* Styling table and figure footnotes with appropriate font size */
    .figure_note .note,
    .figure_note .note .label,
    .table_notes .figure_note_SI,
    .table_notes .figure_note {
        font-size: 12px;
        margin-bottom: 0;
    }

    .bibliography {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }

    .heading {
        font-weight: bold;
        text-align: center;
    }

    .list_change_margin {
        margin-left: -15px;
    }

    .list_change_indentation {
        margin-left: 15px;
        margin-top: -20px;
    }

    .sub_term {
        margin-left: 30px;
    }

    .dual_columns {
        margin-left: 20px;
    }

    .equation_group {
        text-align: center;
    }


    /*CSS added in Phase 2 for XML clean task : End */

    /* CSS added for indentation issue */
    .list ul.no_mark {
        list-style-type: none;
        font-weight: normal;
        font-style: unset;
        text-align: left;
        margin-left: 10px;
        padding-left: 20px;
    }

    /* CSS added for indentation issue */
    /* CA book css issues */
    .chapter_subtitle {
        font-weight: bold;
        text-align: center;

    }

    .adopting_agency {
        font-weight: bold;
    }

    .deletion_marker_nonICC_para:before {
        content: ">";
        font-size: 18px;
        width: 0%;
        float: left;
        margin-left: -20px;
        color: black;
    }

    .no_mark .exception {
        text-indent: 0px !important;
    }

    .acknowledgement_table {
        width: 350px;
        margin: 0 auto;
    }

    .reference_standard_para_width {
        width: 80%;
    }

    .deletion_marker_nonICC_CA:before {
        content: ">";
        font-size: 18px;
        width: 0%;
        float: left;
        margin-left: -30px;
        color: black;
    }

    td .deletion_marker_nonICC_CA:before {
        content: ">";
        font-size: 26px;
        width: 0%;
        position: absolute;
        left: -10px;
        top: -5px;
        color: black;
    }

    .deletion_marker_nonICC_OR:before,
    .deletion_marker_nonICC_CHI:before,
    .deletion_marker_nonICC_PHX:before {
        content: ">";
        font-size: 18px;
        width: 0%;
        float: left;
        margin-left: -30px;
    }

    td .deletion_marker_nonICC_OR:before,
    td .deletion_marker_nonICC_CHI:before,
    td .deletion_marker_nonICC_PHX:before {
        content: ">";
        font-size: 26px;
        width: 0%;
        position: absolute;
        left: -20px;
        top: -5px;
    }

    .amendment_CA {
        font-style: italic;
    }

    .table_div_CA {
        overflow-x: auto;
        overflow-y: hidden;
    }

    table.state_specific {
        td:first-child, th:first-child {
            border-bottom: 1px solid #fff;
            border-left: 1px solid #fff;
        }

        th:first-child {
            border-top: 1px solid #fff;
        }
    }
}

/* Florida specific default classes */
.changed_nonICC_FL td,
.changed_nonICC_FL *,
.changed_nonICC_CB *,
.span.changed_nonICC_CB {
    color: red;
}

.change_aqua_FL td, .change_aqua_FL {
    color: #005D85 !important;
}

.deletion_marker_FL {
    font-size: 26px;
    height: 12px;
    margin-bottom: -10px;
    margin-left: -20px;
    margin-top: -8px;
    width: 40px;
}

.chapter_styling a {
    color: #337AB7!important;
    text-decoration: underline!important;
}
.chapter_styling a:visited {
    color: #8D3EB7!important;
}
.migrate-compare {
    -webkit-user-select: initial !important;
    -moz-user-select: initial !important;
    -ms-user-select: initial !important;
    -ms-user-select: text !important;
    -o-user-select: initial !important;
    user-select: initial !important;
}

span.changed_nonICC_NYC {
    color: #f800f8;
}

/* Washington and Phoenix specific classes added to CSS  Start */
span.changed_nonICC_WA2012
{
    color: #035842;
}

.Margin_document_PHX:before
{
    content:"◆";
    font-size: 15px;
    width: 0%; /* Added 09/02/2020 */
    float: left; /* Added 09/02/2020 */
    margin-left: -30px; /* Added 09/02/2020 */
}

td .Margin_document_PHX:before
{
    content:"◆";
    font-size: 26px;
    width: 0%;
    position: absolute;
    left: -20px;
    top: -5px;
}

ins[data-changed="changed_level2"] {
    color: #FF00FF;
    text-decoration:none;
}
/* Washington and Phoenix specific classes added to CSS  End */
/* 2018 Caricom Regional Energy Efficiency Building Code CSS Start*/
.changed_nonICC_CB table,
.changed_nonICC_CB table td,
.changed_nonICC_CB table tr,
.changed_nonICC_CB table tr td
{
    border-color: red;
    color: red;
}
/* 2018 Caricom Regional Energy Efficiency Building Code CSS End*/
body #app .newIccStyles table tbody td {
    padding-left: 15px !important;
}

/* CSS for text change to Uppercase */
.small-caps {
    text-transform: uppercase !important;

}

.area-field-focus{
    button {
        &:focus {
            outline: 2px solid #21837C;
        }
    }
    div[role="button"] {
        &:focus {
            outline: 2px solid #21837C;
        }
    }
}

.link-color {
    .text-body-1, .v-expansion-panel-content__wrap, .v-card__text, .link-underline  {
        a {
            text-decoration: underline;
        }
    }
    a[href^="mailto"] {
        text-decoration: underline;
    }
}

.v-tooltip__content {
    padding: 0 !important;
    pointer-events: auto !important;
    .tooltip-content {
        display: block;
        padding: 5px 16px;
    }
}

.search-item-breadcrumb {
    a {
        text-decoration: underline;
    }
}
.chapter-section-selected {
    border: 1px solid #757575 !important;
}
.wrap-display-title, .wrap-selected-display-title {
    margin-right: 200px !important;
}

.subscription-table {
    .v-expansion-panel {
      .v-expansion-panel-header {
          padding: 16px 12px;
      }
    }
    .title-section {
      width: 350px;
    }
    .license-section {
      width: 530px;
    }
    &.concurrent {
        th.caption {
            min-width: 150px;
        }
        .subscription-sub-table {
            td {
                min-width: 150px;
            }
        }
    }
    .created-section {
        width: 400px;
    }
    .subscription-sub-table {
      .book-titles {
          display: inline-table;
      }
    }
  }

  .error-message {
    font-size: 12px;
    font-weight: 500;
    color: var(--v-error-base);
  }