
.user-profile-menu {
    max-height: calc(100vh - 70px);
    overflow-y: auto !important;
    .list-item {
        min-height: 49px;
    }
    .list-item-title {
        font-size: 0.67em;
    }
}

