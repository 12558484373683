
.image-border {
    border: 4px solid #FFA201!important;
}
.collectionImageWidthHeight {
    width: 100%;
    height: auto;
}
.singleImageWidthHeight {
    width: auto;
    height: 200px;
}

.title-topic-icon {
    width: 96px;
    height: 96px;
}
