<template>
    <div class="grey lighten-5 container-height">
        <global-header
            :heading="welcomeMessage">
        </global-header>
        <v-container>
            <alert-bar
                class="mt-4"
                @dismiss="dismissNotification"
                :message="subscriptionNotification.message"
                :dismissible="subscriptionNotification.dismissable"
                :showLink="subscriptionNotification.show_link"
                :linkUrl="subscriptionNotification.link_url"
                :linkText="subscriptionNotification.link_text"
                notification-type="warning">
            </alert-bar>
            <v-row>
                <v-col
                    lg="8">
                    <trial-block></trial-block>
                    <outlined-card
                        noPadding>
                        <template v-slot:title>
                            <h2 class="text-h6 font-weight-bold">Titles</h2>
                        </template>
                        <template v-slot:content>
                            <v-tabs
                                v-model="activeTab"
                                align-tabs="left">
                                <v-tab
                                    v-for="tab in tabs"
                                    :key="tab.key"
                                    class="font-weight-bold"
                                    :class="activeTab === tab.key ? '' : 'grey--darken-1'">
                                    {{ tab.name }}
                                </v-tab>
                            </v-tabs>
                            <v-divider>
                            </v-divider>
                            <v-container>
                                <loading-linear
                                    v-if="loading[tabSelected]">
                                </loading-linear>
                                <template v-else>
                                    <v-row align="center">
                                        <v-col>
                                            <p class="fs-16 mb-0" v-if="pagination[tabSelected].totalResults">
                                                {{pagination[tabSelected].totalResults}} titles {{ filters.search ? `for search term ${filters.search}` : '' }}
                                            </p>
                                            <p class="fs-16 mb-0" v-else>
                                                <a
                                                    @click="goToRoute('sections_search_page')">
                                                    Search codes
                                                </a>or browse code categories below:
                                            </p>
                                        </v-col>
                                        <v-col cols="auto">
                                            <titles-filters
                                                v-if="tabSelected !== 'favorites'"
                                                @updateFilters="switchFilters">
                                            </titles-filters>
                                            <v-btn
                                                v-else
                                                outlined
                                                depressed
                                                :href="getRoute('favorites_landing')">
                                                Manage Favorites
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <div id="filterMenu" style="position: relative"></div>
                                    <v-row v-if="filters.category.length || filters.collections.length">
                                        <v-col>
                                            <v-chip
                                                class="primary--text mr-2 mb-1"
                                                color="primaryLight">
                                                    <span class="fs-16">
                                                        {{filters.category[0] || filters.collections[0]}}
                                                    </span>
                                                <v-icon
                                                    right
                                                    @click="resetFilters()">
                                                    mdi-close
                                                </v-icon>
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                    <title-list
                                        v-if="titles[tabSelected].length"
                                        :loading="loading[tabSelected]"
                                        :titles="titles[tabSelected]"
                                        :items-per-page="20*pagination[tabSelected].currentPage">
                                    </title-list>
                                    <template v-else>
                                        <category-collections-buttons>
                                        </category-collections-buttons>
                                    </template>
                                    <v-row
                                        v-if="titles[tabSelected].length < pagination[tabSelected].totalResults"
                                        justify="center">
                                        <v-col cols="auto">
                                            <v-btn
                                                text
                                                color="primary"
                                                :loading="showMoreLoading[tabSelected]"
                                                :disabled="showMoreLoading[tabSelected]"
                                                @click="getTitles(true)">
                                                Show More
                                                <v-icon right>
                                                    mdi-chevron-down
                                                </v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-container>
                        </template>
                    </outlined-card>
                </v-col>
                <v-col
                    lg="4">
                    <sidebar></sidebar>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import GlobalHeader from "../../components/headers/GlobalHeader.vue";
import OutlinedCard from "../../components/cards/OutlinedCard.vue";
import Sidebar from "./Sidebar/Sidebar.vue";
import TrialBlock from "./TrialBlock.vue";
import LoadingLinear from "../../components/loaders/LoadingLinear.vue";

import {TitlesApi} from "../../api/Titles";
import {FavoritesApi} from "../../api/FavoritesApi";
import TitleList from "../../components/content/title/TitleList.vue";
import TitlesFilters from "../../components/dashboard/library/TitlesFilters.vue";
import ProfilePreferences from '../../components/inc/mixins/profilePreferences.mixin';
import {ArrayUtils} from "../../util/ArrayUtils";
import CategoryCollectionsButtons from "../../components/content/partials/categoryCollectionsButtons.vue";
import {DateUtil} from "../../util/DateUtil";
import {StringUtil} from "../../util/StringUtil";
import {EasyStorage} from "../../classes/EasyStorage";
import SubscriptionNotificationsApi from "../../api/SubscriptionNotificationsApi";
import AlertBar from "../../components/notifications/AlertBar.vue";
import {AppParameters} from "../../AppParameters";

export default {
        data() {
            return {
                subscriptionNotification: {
                    message: "",
                    dismissable: true,
                    show_link: false,
                    link_url: ""
                },
                isFirstLogin: true,
                tabs: [
                    {
                        name: 'Recently Viewed',
                        key: 'recentlyViewed'
                    },
                    {
                        name: 'Your Premium Titles',
                        key: 'premiumTitles'
                    },
                    {
                        name: 'Favorites',
                        key: 'favorites'
                    }
                ],
                activeTab: 0,
                loading: {
                    recentlyViewed: true,
                    premiumTitles: true,
                    favorites: true
                },
                showMoreLoading: {
                    recentlyViewed: false,
                    premiumTitles: false,
                    favorites: false
                },
                titles: {
                    recentlyViewed: [],
                    premiumTitles: [],
                    favorites: []
                },
                pagination: {
                    recentlyViewed: {
                        currentPage: 1
                    },
                    premiumTitles: {
                        currentPage: 1
                    },
                    favorites: {
                        currentPage: 1
                    }
                },
                filters: {
                    search: '',
                    category: [],
                    collections: [],
                }
            }
        },
        components: {
            CategoryCollectionsButtons,
            TitlesFilters,
            TitleList,
            LoadingLinear,
            OutlinedCard,
            GlobalHeader,
            Sidebar,
            TrialBlock,
            AlertBar
        },
        mixins: [
            ProfilePreferences
        ],
        computed: {
            welcomeMessage() {
                if (this.userFirstName) {

                    if (this.isFirstLogin) {
                        return `Welcome, ${StringUtil.ucWord(this.userFirstName)}`;
                    } else {
                        return `Welcome back, ${StringUtil.ucWord(this.userFirstName)}`;
                    }
                }
                return `Welcome`;
            },
            tabSelected() {
                return this.tabs[this.activeTab].key;
            },
            isFirstLoginRecent() {

                const user = this.$store.getters.getUser;

                // returns TRUE is first login was less than a minute ago
                if (user && user.first_login_at) {
                    const diff = DateUtil.differenceInSeconds(user.first_login_at);
                    return diff && diff < 60;
                }

                return false;
            },
            isAutoRenewEnabled(){
                return AppParameters.subscriptions_autorenew_enabled;
            },
        },
        watch: {
            activeTab() {
                this.getTitles()
            }
        },
        methods: {
            async getSubscriptionNotifications() {
                let response = await SubscriptionNotificationsApi.getNotificationsDashboard();
                this.subscriptionNotification = response.data?.subscription_notifications[0] || {};
            },
            async dismissNotification() {
                await SubscriptionNotificationsApi.dismissNotification(this.subscriptionNotification.hash);
            },
            async getTitles(showMore = false, reset = false) {
                let page = 1;
                if (showMore) {
                    page = this.pagination[this.tabSelected].currentPage + 1;
                    this.showMoreLoading[this.tabSelected] = true;
                }
                if (reset) {
                    this.loading[this.tabSelected] = true;
                }

                let query = {
                    limit: 20,
                    page: page,
                    search: this.filters.search,
                    category: this.filters.category,
                    collections: this.filters.collections
                }
                switch (this.tabSelected) {
                    case 'recentlyViewed':
                        query.group = 'recently_viewed';
                        break;
                    case 'premiumTitles':
                        query.group = 'all';
                        query.mine = true;
                        break;
                }

                try {
                    let response;
                    if (this.tabSelected === 'favorites') {
                        response = await FavoritesApi.getFavorites({
                            page: page,
                            limit: 20,
                        });
                    } else {
                        response = await TitlesApi.getTitles(query);
                    }
                    if (showMore) {
                        const newData = response.data.data;
                        this.titles[this.tabSelected].push(...newData);
                    } else {
                        this.titles[this.tabSelected] = response.data.data;
                    }
                    this.pagination[this.tabSelected] = response.data.pagination;
                } catch (error) {
                    console.error(error);
                } finally {
                    this.loading[this.tabSelected] = false;
                    this.showMoreLoading[this.tabSelected] = false;
                }
            },
            switchFilters(data) {
                const categoryNames = ArrayUtils.convertObjectToArray(data.categories, 'name');
                const collectionIds = ArrayUtils.convertObjectToArray(data.collections, 'id');

                this.filters.search = data.search;
                this.filters.category = categoryNames;
                this.filters.collections = collectionIds;
                this.getTitles(false, true);
            },
            resetFilters() {
                this.filters.category = [];
                this.filters.collections = [];
                this.getTitles(false, true);
            }
        },
        created() {

            const visitedBefore = EasyStorage.has('dashboard:visited');

            if (visitedBefore) {
                this.isFirstLogin = false;
            } else {
                localStorage.setItem('dashboard:visited', 'yes');
            }
        },
        mounted() {
            this.getTitles();
            if (this.isAutoRenewEnabled) {
                this.getSubscriptionNotifications();
            }
        }
    }
</script>

<style scoped>
    .v-tab--active {
        background: unset !important;
        color: var(--v-primary-base) !important;
    }
</style>