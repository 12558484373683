
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
.cdp-dialog {
    box-shadow: none;
    border-radius: 0;
    .oswald {
        font-family: "Oswald", Arial;
    }
    .z-fix {
        z-index: 203;
    }
    .font-40 {
        font-size: 40px;
    }
    .v-tab {
        justify-content: left;
    }
    .custom-row {
        position: relative;
        .triangle {
            border-style: solid;
            width: 0;
            height: 0;
        }
        .top-background, .bottom-background {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 90%;
            width: 98%;
        }
        .bottom-background {
            bottom: unset;
            top: 0;
            left: unset;
            right: 0;
            border-radius: 0 0 0 4px;
        }
    }
    .rounded-close {
        border-radius: 4px;
    }
    .rounded-card {
        border-radius: 0 4px!important;
    }
    .v-slide-group__wrapper {
        height: 100%;
        overflow-y: scroll;
        display: block;
    }
    .expand-button {
        position: absolute;
        z-index: 1;
        bottom: 10px;
    }
}
.vue-friendly-iframe {
    iframe {
        width: 100%;
        height: 100%;
    }
}
