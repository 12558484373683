
.twitter-x {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE3LjggMTIuNyAyOC43IDBIMjZsLTkuMyAxMUw5IDBILjNsMTEuNSAxNi43TC4zIDMwSDNsMTAtMTEuN0wyMSAzMGg4LjdMMTcuOSAxMi43Wm0tMy41IDQuMUMxNC4xIDE2LjYgNC4zIDIuNiAzLjggMmg0YzQuNyA2LjggMTMuNSAxOS4zIDE4LjMgMjYuMmgtNGwtNy44LTExLjNaIi8+PC9zdmc+);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    color: transparent;
    display: block;
    font-size: 0;
    height: 20px;
    text-decoration: none;
    transition: transform .2s ease-in-out;
    width: 24px;

    align-items: center;
    display: inline-flex;
}
.footer-solutions a {

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 80px;
  }

  display: inline-block;

/*
  width: 80px;
  height: 50px;

  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  display: block;
  font-size: 0;
  margin: 0 auto;
  text-decoration: none;
  transition: transform .2s ease-in-out;*/

  &:hover > img {
    transform: scale(1.1);
  }

}
