
.menu-icons {
  img {
    width: 50px;
  }

  a:hover {
    background: #eff4f2;
  }
}
@media all and (-ms-high-contrast:none)
{
    *::-ms-backdrop, .menu-icons {
        min-width: 500px;
    }
}
