
#toc-wrapper-container {
    .chapter-wrapper {
        overflow-y: scroll;
    }
}
.legend-wrapper {
    .mini-text {
        font-size: 10px;
    }
    .v-tabs {
        .v-tabs__container {
            height: auto;
        }
        .active-tab-border {
            border: 1px solid #EEEEEE!important;
        }
    }
}
.toc-chapter-item:hover {
    background-color: var(--v-monotonesLightGreen-base) !important;
}
