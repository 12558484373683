
.v-data-table-header {
    .manage-user-tbl-header {
        white-space: nowrap !important;
        padding: 0 0 0 18px!important;
    }
}
.no-border{
    border: none !important;
}
