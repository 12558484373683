
#navigation-app {
    z-index: 200;
}
.v-badge__badge {
    padding: 0px 6px;
}
.v-badge__wrapper{
    top: 5px;
}
.menu-login {
    a {
        text-decoration: none;
    }
}
#profile-button {
    .v-btn__content {
        display: block;
    }
}
