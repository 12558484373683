@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

[v-cloak] > * { display:none; }
[v-cloak]::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../images/pageloading.gif');
    background-position: center center;
    background-repeat: no-repeat;
}

.disable-selection {
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

body {
    #app {
        //New Global SCSS
        background: #FFFFFF;
        a:not(.v-btn):hover {
            color: #327358;
        }
        a.btn:hover {
            color: #ffffff;
        }
        .cp:hover {
            cursor: pointer;
        }
        .v-btn {
            text-transform: none;
            letter-spacing: 0;
        }
        .content-container {
            max-width: 1200px;
            margin: 0 auto;
        }
        .container-height {
            min-height: 100vh;
        }
        .text-decoration-none {
            text-decoration: none;
        }
        .license-book-title {
            cursor: pointer!important;
        }
        .wave-background {
            position: relative;
            svg {
                widht: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                path {
                    stroke: none;
                    fill: #EEEEEE;
                }
            }
        }
        .fade {
            opacity: .5;
        }
        .pdf {
            position: absolute;
            bottom: 60px;
            right: -5px;
        }

        .icon-left,
        .icon-right,
        .icon-center {
            position: absolute;
            bottom: -12px;
            z-index: 6;
            width: 30px;
        }
        .icon-left{
            right: 88px;
        }
        .icon-right {
            right: 48px;
        }
        .icon-center {
            // because icon itself is 30 pixels wide
            left: calc(50% - 15px);
        }
        .list__tile {
            a {
                text-decoration: none;
            }
        }
        .menu__content {
            border-radius: 0;
        }
        .v-dialog {
            overflow: visible;
        }
        .license-book-image {
            max-width: 50px;
        }
        .video-wrapper {
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 25px;
            height: 0;

        }
        .video-wrapper iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .info-banner {
            top: initial !important;
            max-width: 100%;
            z-index: 40;
            .snack__wrapper {
                width: initial;
                max-width: initial;
                .snack__content {
                    text-align: center;
                }
            }
            a {
                color: white;
            }
        }
        .chapter-wrapper-mobile {
            min-height: 100vh;
            height: 100%;
            .chapter-toggle:hover {
                cursor: pointer;
            }
            .rotate{
                -moz-transition: all .1s linear;
                -webkit-transition: all .1s linear;
                transition: all .1s linear;
            }
            .rotate.down{
                -moz-transform:rotate(90deg);
                -webkit-transform:rotate(90deg);
                transform:rotate(90deg);
            }
        }
        .body {
            min-height: 100vh;
        }
        .light-border-color {
            border-color: #DDDDDD!important;
        }
        .accent2-top-border {
            border-top: 5px solid #FFA201;
            border-right: 1px solid #DDDDDD;
            border-left: 1px solid #DDDDDD;
            border-bottom: 1px solid #DDDDDD;
        }
        blockquote.quoted {
            position: relative;

            &:before {
                font-family: sans-serif;
                color: #275741;
                position: absolute;
                content: open-quote;
                font-size: 8em;
                margin-left: -0.5em;
                margin-top: -0.4em;
            }

            &:after {
                font-family: sans-serif;
                color: #275741;
                position: absolute;
                content: close-quote;
                font-size: 8em;
                bottom: 0;
                right: 0;
                margin-right: -0.5em;
                margin-bottom: -0.8em;
            }
        }
        .question-details {
            p {
                font-size: 16px!important;
            }
            a {
                text-decoration: underline;
            }
        }
        .v-input .v-input__slot {
            background: #ffffff;
        }
    }
    @supports (-ms-ime-align:auto) {
        .bodyTXT {
            margin-left:-105px;
        }
    }
    .feedback-help-buttons {
        .livechat-image-button, .feedback-image-button {
            cursor: pointer;
            z-index: 200;
            right: 0;
            position: fixed;
            bottom: 40px;
        }
        .feedback-image-button {
            bottom: 150px;
        }
    }
    .trademark {
        font-family: 'Open Sans',serif;
    }
    .print_restrict_message {
        display: none;
    }
    .primary:hover {
        color: #FFFFFF;
    }
    .v-card__text {
        color: rgba(0,0,0,.9)!important;
    }
    .mx {
        max-width: 100%!important;
    }
    .fs-14 {
        font-size: 14px!important;
        line-height: 22px!important;
    }
    .fs-16 {
        font-size: 16px!important;
        line-height: 24px!important;
    }
    .fs-18 {
        font-size: 18px!important;
        line-height: 26px!important;
    }
    .fs-20 {
        font-size: 20px!important;
        line-height: 26px!important;
    }
    .fs-22 {
        font-size: 22px!important;
        line-height: 28px!important;
    }
    .fs-24 {
        font-size: 24px!important;
        line-height: 28px!important;
    }
    .fs-32 {
        font-size: 32px!important;
        line-height: 38px!important;
    }
    .fs-36 {
        font-size: 36px!important;
        line-height: 46px!important;
    }
    .fs-40 {
        font-size: 40px!important;
        line-height: 50px!important;
    }
    .height-auto {
        height: auto !important;
    }
    #contentParent section {
        clear: both;
        position: relative;
    }
    .error-container {
        h1 {
            text-align: center;
            font-size: 45px;
        }
        h2 {
            text-align: center;
            font-size: 25px;
        }
        .image-error {
            max-width: 500px;
            width: 100%;
            height: auto;
        }
        a {
            text-decoration: underline;
        }
        .links {
            list-style: none;
            li {
                display: inline-block;
            }
            li::after {
                content: "|";
                font-size: 110%;
                float: right;
                padding-left: 6px;
                padding-right: 3px;
            }
            li:last-child::after{
                display: none;
            }
        }
    }
}

@media print {
    .no-print, .no-print *
    {
        visibility: hidden;

        .print_restrict_message {
            display: block !important;
            visibility: visible;
        }
    }

    .print_restrict_message, .print_restrict_message * {
        visibility: visible;
        display: block;
    }

    .print_restrict_message {
        position: absolute;
        left: 0;
        top: 0;
    }
    .no-print-text {
        display: block;
    }
}

@media screen and (max-width: 1263px) {
    body {
        #app {
            .application {
                .menu__content {
                    .list {
                        a {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 959px) {
    #app {
        .application {
            #pdfViewer {
                margin-top: 20px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    #app {
        .application {
            .body {
                .dashboard {
                    .library {
                        .tabs {
                            .tabs__container {
                                .tabs__item {
                                    padding: 0 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    #app {
        .application {
            .body {
                .search-result-list, .trial-book-result-list {
                    .result-items-container {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

//IE CSS Fixes.
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    html {
        width: 100%;
        overflow-x: scroll;
        body {
            #app {
                .iefl {
                    float: left!important;
                }
                .iefr {
                    float: right!important;
                    img {
                        width:100%;
                        max-height: 500px;
                    }
                }
                .input-group .input-group__append-icon, .input-group .input-group__prepend-icon {
                    padding: 0 22px 0 0;
                }
                .v-application {
                    display: block;
                    .body {
                        .content-signup {
                            .input-group {
                                .input-group__input {
                                    input {
                                        padding: 0!important;
                                    }
                                }
                            }
                        }
                        .about-pa-container {
                            .layout {
                                display: block;
                            }
                        }
                        .dashboard-sharing {
                            .expansion-panel {
                                margin: 30px 0 0 0;
                            }
                            .ie-checkbox {
                                top: 32px;
                            }
                        }
                        .deletion_marker_paragraph {
                            height: auto!important;
                            padding: 15px 0 0 0;
                            section {
                                margin: 0 0 0 25px;
                            }
                        }
                        .deletion_marker {
                            height: auto!important;
                        }
                        #non-sticky {
                            margin-left: 0!important;
                        }
                        .content-container-wide {
                            max-width: 100vw;
                        }
                    }
                    .banner {
                        img {
                            max-width: 100%;
                        }
                    }
                }
                .info-banner {
                    top: 190px;
                }

                .license-configuration {
                    table.v-table tbody td:first-child, table.v-table tbody td:not(:first-child), table.v-table tbody th:first-child, table.v-table tbody th:not(:first-child), table.v-table thead td:first-child, table.v-table thead td:not(:first-child), table.v-table thead th:first-child, table.v-table thead th:not(:first-child) {
                        padding: 0 !important;
                    }

                }
                .v-list--dense .v-list-item, .v-list-item--dense {
                    height: 40px;
                }
            }
        }
    }
}

// Footer
.heightAuto {
    height: 17px !important;
}

img.mr-2.px-2.pl-0 {
    padding-left: 0 !important;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

p.caption.ma-0  {
    color: #d8d8d8;
}

.v-application a {
    color: #d8d8d8;
    text-decoration: none;
    font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}

.v-application a:not(.v-btn):hover {
    color: #49a942;
    text-decoration: none;
}

.footer-box:nth-child(odd) {
    margin-top: -30px;
}

.footer-box:nth-child(even) {
    margin-top: 8px
}

p.copyright.caption {
    color: #fff;
}

@media screen and (max-width: 601px) {
    body #app .application .footer-container .footer-box {
        position: relative;
        margin-top: 10px;
        height: 67px;
        width: 50%;
        clear: left;
    }
    body #app .application .footer-container .footer-box .footer-record {
        float: left;
        clear: left;
        margin: 5px;
    }
    body #app .application .footer-container .copyright{
        padding: 292px 0 0 0;
    }
    .footer-box:last-child {
        margin-bottom: 30px;
    }

}

@media screen and (max-width: 1263x) {
    body #app .application .footer-container .copyright{
        padding: 292px 0 0 0;
    }
}

a.v-list__tile.v-list__tile--link.v-list__tile--active.theme--light.primary--text {
    color: #ffffff !important;
    background-color: #0b5940 !important;
}

ol.breadcrumb.mt-n2 {
    height: 25% !important;
}
.advance_seperator {
    display: flex;
    flex-direction: row;
    color: grey;
    font-weight: bold;
}
.advance_seperator:before, .advance_seperator:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
}
.advance_seperator:before {
    margin-right: 10px;
    color: grey;
}
.advance_seperator:after {
    margin-left: 10px;
    color: grey;
}
.codes_hover:hover {
    color: rgba(0,0,0,.87) !important;
}
.trial_hover {
    color: #fff !important;
}

.oxygen {
    font-family: Oxygen, serif !important;
}
.roboto {
    font-family: Roboto, sans-serif !important
}

.button-text-wrap > .v-btn__content {
    width: 100%;
    white-space: normal;
}

.d-flex-not-important {
    display: flex;
}

.vuetify-checkbox-label {
    display: inline-block;
    cursor: pointer;
    line-height: 1.4 !important;
}

.vuetify-checkbox {
    height: 20px;
    width: 20px;
    cursor: pointer;
    border-radius: 50%;
    accent-color: #0b5940;
}

.w-full {
    width: 100%;
}

.max-w-full {
    max-width: 100%;
}

.h-full {
    height: 100%;
}

.min-h-full {
    min-height: 100%;
}

.border {
    border: thin solid rgba(0, 0, 0, .12) !important;
}

.border-primary {
    border: 1px solid #0b5940 !important;
}

.border-transparent {
    border-color: transparent !important;
}

.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}

.overscroll-contain {
    overscroll-behavior: contain;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.list-none {
    list-style-type: none;
}
.cursor-wait {
    cursor: wait;
}
.toc-chapter-item-focused:focus {
    outline: none;
    padding-left: 2px !important;
    background-color: var(--v-monotonesLightGreen-base) !important;
}

/* vertically center contents inside of tippy */
.tippy-box > .tippy-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    svg {
        margin-right: 3px;
    }
}
.notes-bookmarks-section{
    .note-list-item{
        .default-color{
            color: initial !important;
        }
        .highlight-text p{
            color: initial !important;
            margin-bottom: 0px !important;
        }
    }
}
