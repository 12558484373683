
.exam-timeline-container {
    img {
        max-width: 100%;
        filter: drop-shadow(0px 2.94444px 16.1944px rgba(0, 0, 0, 0.22));
        border-radius: 11.7778px;
    }
    h1 {
        line-height: 42px;
    }
    .get-started-button{
        position: absolute;
        top: 13%;
        left: 38%;
    }
    .v-timeline::before {
        top: 40px;
        height: calc(100% - 100px)
    }
    @media (min-width: 700px) and (max-width: 1000px){
        .get-started-button{
            position: absolute;
            top: 6%;
            left: 30%;
        }
    }
}
