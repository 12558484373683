@import './variables/colors';

.newIccStyles {

    /* content styling for XML2.0 */
    table {
        overflow-x: auto;

        &.border-less {
            border: none !important;
            border-collapse: separate !important;
            th, td {
                border: none !important;
            }
        }
    }
    figure table {
        overflow-x: auto;
    }

    .xml2-chapter {
        p, ul, ol {
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: 'Source Sans Pro', sans-serif;
            line-height: 28px; /* 175% */
        }

        table p {
            line-height: 20px;
            text-align: left;
            font-size: 14px;
        }

        .table_notes {
            .table_note {
                padding: 0 12px;
                p {
                    line-height: 16px;
                    font-size: 12px;
                }
                > p {
                    display: block;
                }
                &.has-number {
                    // this is to make those table notes that have an a.b.c. list-like marker
                    // look more like list items
                    margin-left: 8px; /* Adjust the indentation as needed */
                    text-indent: -8px; /* Counteract the margin on the first line */
                    .list-marker {
                        min-width: 8px;
                        display: inline-block;
                    }
                }
                ol.custom {
                    .label {
                        font-size: 12px !important;
                        line-height: 16px;
                    }
                }
            }
        }

        figure.table figcaption {
            text-align: left;
        }

        .equation {
            padding: 12px 0 0 0;
            display: grid;
            grid-template-columns: auto 1fr; /* Two columns: one for label, one for math */
            grid-auto-rows: min-content;
            gap: 8px 30px;
            .label {
                font-size: 16px;
                line-height: 28px; /* 175% */
                font-weight: bold;
                align-self: center;
            }
            math {
                font-size: large;
                align-self: center;
                /* Allow line breaks */
                display: inline-flex;
                align-items: center;
                gap: 2px;
                flex-wrap: wrap;
                row-gap: 10px;

                mi[mathvariant="italic"] {
                    font-style: italic;
                }
            }

            > *:not(.label):not(math) {
                grid-column: span 2; /* Span across both columns to form a new row */
            }
        }

        .where {
            margin: 0 0 0 24px;
        }

        &.copyright-page {
            .publication-history,
            .copyright-statements,
            .legal-notice,
            .trademarks {
                display: flex;
                flex-direction: column;
                padding-bottom: 24px;
            }

            .publisher-note {
                border-radius: 4px;
                border: 1px solid #bebebe;
                background-color: #f6f6f6;
                padding: 16px 32px;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 28px;
                margin-bottom: 12px;
            }
        }

        .promulgator {
            .promulgator-wrapper {
                display: flex;
                flex-direction: column;
                align-self: stretch;
                border-radius: 4px;
                border: 1px solid #E6E6E6;
                padding: 0px;
                margin-top: 32px;
                .promulgator-acronym-row {
                    display: flex;
                    margin: 0px;
                    padding: 16px;
                    gap: 16px;
                    align-self: stretch;
                    background: var(--icc-light-green, #E7EEEC);
                    @media (max-width:900px){
                        flex-direction: column;
                    }
                    .promulgator_acronym {
                        width: 20%;
                        color: #0084FF;
                        font: normal 700 40px 'Source Sans Pro', sans-serif;
                        line-height: 48px;
                        a.url {
                            text-decoration: none !important;
                        }
                        @media (max-width:1350px){
                            width:40%;
                        }
                        @media (max-width:900px){
                            width:100%;
                        }
                    }
                    .promulgator-address {
                        color: #555;
                        font: normal 400 12px 'Source Sans Pro', sans-serif;
                        line-height: 20px;
                        p {
                            margin: 0px;
                        }
                    }
                }
                .referenced_standards {
                    display: flex;
                    padding: 0px 16px 16px 16px;
                    flex-direction: column;
                    align-items: flex-start;
                    align-self: stretch;
                    .reference_standard {
                        display: flex;
                        padding: 12px 0px;
                        align-items: flex-start;
                        gap: 16px;
                        align-self: stretch;
                        &:not(:first-child) {
                            border-top: 1px solid #E6E6E6;
                        }
                        .reference_standard_number {
                            color: #000;
                            font: normal 600 14px 'Source Sans Pro', sans-serif;
                            line-height: 22px;
                            width: 20%;
                            @media (max-width:1350px){
                                width:40%;
                            }
                        }
                        .ref_data {
                            color: #000;
                            font: normal 400 14px 'Source Sans Pro', sans-serif;
                            line-height: 22px;
                            display: flex;
                            flex-direction: column;
                            width: 80%;
                            @media (max-width:1350px){
                                width:60%;
                            }
                            .reference_standard_title {
                                text-align: left;
                            }
                            a.section_reference {
                                text-decoration: none !important;
                            }
                        }
                    }
                }
            }
        }

        div.index-entries {
            display: flex;
            flex-direction: column;
            gap: 2px;

            div.index-entry {
                display: flex;
                flex-wrap: wrap;
                gap: 36px;

                &.primaryie {
                    border-top: 1px solid var(--monotones-color-white-stroke, #E6E6E6);
                }

                &:hover {
                    background: #F1F1F1;
                }

                div.index-term {
                    width: calc(30% - 24px); // exclude padding
                    overflow: hidden;
                    color: #000;
                    text-overflow: ellipsis;
                    font: normal 600 14px 'Source Sans Pro', sans-serif;
                    // line-height: 28px;
                    line-height: 22px;
                    padding: 4px 12px;
                    span.term-display {
                        padding-left: 4px;
                    }
                }
            }

            div.sub-entries {
                div.index-term {
                    font-weight: 400;
                }
                &.secondarie {
                    div.index-term {
                        padding-left: 20px;
                    }
                    // width: calc(36% - 15px);
                }
                &.tertiaryie {
                    div.index-term {
                        padding-left: 30px;
                    }
                }
            }

            div.index-links {
                display: flex;
                flex-wrap: wrap;
                max-width: calc(70% - 12px);
                padding: 4px 0px;
                gap: 12px;

                a {
                    color: var(--others-color-link, #0084FF) !important;
                    white-space: nowrap;
                    font: normal 400 14px 'Source Sans Pro', sans-serif;
                    line-height: 24px;
                    text-decoration-line: underline;
                }
            }
        }

        ul {
            &.disc {
                list-style-type: disc;
                padding-left: 40px;
            }
            p {
                margin: 0px;
            }
        }

        figure.table {
            figcaption {
                overflow: hidden;
                color: #000;
                text-overflow: ellipsis;
                font: normal 700 18px 'Source Sans Pro', sans-serif;
                line-height: 26px;
                .table_number {
                    padding-left: 4pt;
                }
            }
        }

        figure.figure {
            figcaption {
                overflow: hidden;
                color: #000;
                text-overflow: ellipsis;
                font: normal 700 18px 'Source Sans Pro', sans-serif;
                line-height: 26px;
                .table_number {
                    padding-left: 4pt;
                }
            }
            img {
                margin: 25px auto;
                &[src$=".svg"] {
                    // this overrides svg images' internal "static" sizing
                    // like <svg height="1in" width="1in"... >
                    // and let's them be responsive -- fill the container
                    min-width: 100%;
                }
            }
            .figure_notes,
            .caption {
                display: flex;
                padding: 12px;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;
                border-radius: 4px;
                background: #F6F9FC;
                .figure_note {
                    color: #000;
                    font: normal 400 10px 'Source Sans Pro', sans-serif;
                    line-height: 18px;
                }
            }
        }

        img.inline-mediaobject {
            max-width: 100%;
            &.align-left {
                // just to undo the general text-align: center;
                text-align: left;
                float: left;
                margin: 0 15px 15px 0;
            }
            &.align-right {
                text-align: right;
                float: right;
                margin: 0 0 15px 15px;
            }
        }

        .mediaobject {
            display: block;
            width: 100%;
            img {
                display: inline-block;
                max-width: 100%; /* Ensures the image does not overflow its container */
                height: auto; /* Maintains the aspect ratio */
                &.align-img-left {
                    text-align: left;
                    float: left;
                    margin: 0 15px 15px 0;
                }
                &.align-img-right {
                    text-align: right;
                    float: right;
                    margin: 0 0 15px 15px;
                }
                &.align-img-center {
                    text-align: center;
                    margin: 0 auto; /* Centers the image horizontally */
                    display: block; /* Ensures centering works as intended */
                }
            }
        }

        table {
            td {
                color: #424242;
                font: normal 400 12px 'Source Sans Pro', sans-serif;
                line-height: 20px;
                p, span {
                    margin: 0;
                }
            }
            thead {
                tr {
                    td {
                        background: #E7EEEC;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        &.header-cell {
                            color: #000000;
                            background: #E7EEEC;
                        }
                        &.center {
                            p {
                                text-align: center;
                                span {
                                    text-align: center;
                                }
                            }
                            &:not(.header-cell) {
                                p {
                                    display: block;
                                }
                            }
                        }
                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }

        table.informal {
            border: 1px solid #E6E6E6;
            border-radius: 4px;
            td {
                border: 1px solid #CCC;
            }
            thead > tr > td,
            thead > tr > th {
                p {
                    text-align: center;
                    text-transform: uppercase;
                }
            }
        }

        div.where {
            .varname {
                float: left;
                margin-right: 3px;
                margin-top: 1px;
                white-space: nowrap;
            }
            div.where-item {
                display: flex;
                justify-content: flex-start;
                span.vardesc {
                    padding-left: 4pt;
                    > p {
                        display: inline;
                    }
                }
            }
        }

        div.history {
            overflow: hidden;
            color: #000;
            text-overflow: ellipsis;
            font: italic 400 16px 'Source Sans Pro', sans-serif;
            line-height: 28px;
            padding-bottom: 24px;
        }

        .term_group {
            p.main_term {
                display: inline;
                padding-right: 4pt;
            }
            .definition.content_inline {
                p:first-of-type {
                    display: inline;
                }
                p:not(:first-of-type) {
                    display: block;
                }
            }
        }

        ins {
            color: blue;
            text-decoration: none;
        }

        del[role="delete"],
        .delete {
            display: none;
        }

        .deletion_marker {
            &.inline {
                position: absolute;
                left: -32px;
            }
            &.in-list {
                width: 0px;
                margin-left: 0px;
            }
        }

        ins[data-changed="changed_level0"] {
            color: #00F;
            text-decoration: none;
        }

        ins[data-changed="changed_level1"] {
            color: #F00;
            text-decoration: none;
        }

        ins[data-changed="changed_level2"] {
            color: #F0F;
            text-decoration: none;
        }

        span.phrase {
            font-style: italic;
        }

        span[role="jurisdiction-insert"] {
            font-style: normal;
        }

        .no-text-change,
        .no-text-change span.italic,
        .no-text-change span.phrase {
            color: black !important;
        }

        .tgroup {
            color: #000;
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16x;
            line-height: 24px;
            &:has(.tg-title) .tg-number:not(.no-colon) {
                // show ':' after the numeric portion of title only when textual portion is present
                &:after {
                    content: ':';
                }
            }
            .tg-super-title {
                display: block;
                text-align: left;
                font-size: 28px;
                line-height: 30px;
                margin-bottom: 40px;
            }
            .tg-sub-title {
                display: block;
            }
            &.level-1 {
                text-align: left;
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 40px;
            }
            &.level-2 {
                font-size: 22px;
                line-height: 24px;
                margin-top: 0px;
                margin-bottom: 0px;
                &.part {
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 8px;
                    font-style: italic;
                }
            }
            &.level-3 {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 8px;
            }
            &.level-4 {
                font-size: 18px;
                line-height: 24px;
            }
            &.level-5 {
                font-size: 18px;
                line-height: 24px;
            }
            &.level-6 {
                font-size: 18px;
                line-height: 24px;
            }
            &.level-7 {
                font-size: 18px;
                line-height: 24px;
            }
        }

        .informal-figure {
            clear: both; /* Clears any floated elements */
            overflow: hidden; /* Ensures it contains floated children */
            display: block; /* Ensures proper block-level rendering */
        }

        .no-break {
            white-space: nowrap;
        }

        .content-alert {
            // Base styling for all alert boxes
            padding: 1rem;
            border-left: 6px solid;
            border-radius: 4px;
            margin-bottom: 1rem;
            font-size: 1rem;
            line-height: 1.5;
          
            // Nested styles for specific types
            &.warning {
              border-color: #f5a623 !important;
              background-color: #fff4e5 !important;
              color: #7a410f;
            }
          
            &.caution {
              border-color: var(--v-primary-base);
              background-color: #d6f5e5;
              color: var(--v-primary-base);
            }
          
            &.note {
              border-color: #1890ff;
              background-color: #e6f7ff;
              color: #004085;
            }
        }

        .boxed-text {
            margin: 5pt 0;
            padding-top: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            border: 1px solid #E6E6E6;
        }
          
    }
}

#contentParent {
    .xml2-chapter {
        ul {
            padding-left: 24px!important;
            list-style-type: none;
            &.disc {
                list-style-type: disc;
            }
            &.circle {
                list-style-type: circle;
            }
            &.square {
                list-style-type: square;
            }
            &.hyphen li::marker {
                content: "- ";
            }
            &.check li::marker {
                content: "✔ ";
            }
        }
        ol {
            padding-left: 24px!important;
            // list-style: none;
            list-style-type: none;
            list-style-position: outside;

            &.arabic {
                list-style: none;
                counter-reset: list;
                margin: 0;
                padding: 0;
                > li {
                    display: table;
                    counter-increment: list;
                    margin-bottom: 0.6em;
                    &:before {
                        content: counters(list, ".") ". ";
                        display: table-cell;
                        padding-right: 0.9em;
                    }
                    p {
                        display: inline;
                    }
                }
            }
            &.lower-alpha {
                list-style-type: lower-alpha;
            }
            &.upper-alpha {
                list-style-type: upper-alpha;
            }
            li:has(.list-item-flex) {
                display: flex;
                align-items: flex-start;
                flex: 1 1 auto; /* Ensure flex grows to available space */
                width: 100%; /* Ensure li stretches to full width */
                .list-item-flex {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    margin: 0;
                    flex: 1;
                    padding-left: 0;
                    padding-right: 1em; // without this the right side gets clipped off by a char or so
                    word-wrap: break-word; /* Ensures long words break */
                    word-break: break-word; /* Older support for word wrapping */
                }

                > span.label {
                    font-size: clamp(12px, 16px, 16px);
                    margin-right: 0.9em;
                    display: block;
                    white-space: nowrap; /* Prevent wrapping */
                    flex-shrink: 1; /* Allow shrinking */
                    flex-grow: 0; /* Do not grow beyond content */
                }
            }
        }

        .exception {
            // list inside exception - keep separate counter
            ol.arabic {
                counter-reset: exception-list;
                > li {
                    counter-increment: exception-list;
                    &:before {
                        content: counters(exception-list, ".") ". ";
                    }
                }
            }
        }

        figure {
            table {
                border: 1px solid #E6E6E6;
                border-radius: 4px;
                td {
                    border: 1px solid #CCC;
                }
            }
        }
    }

    /* content styling for OLD-XML */
    .chapter_part 
    .book_chapter_part_section 
    .ICCSECTION_l2-L7  
    .ICCSECTION_l3-L8 {
        //max-width: 1024px;
    }

     /* Table/Figure label CSS for old xml */
     .FLCFIGURE_L1,
     .FLCFIGURE_L2,
     .FLCFIGURE_L3,
     .FLCFIGURE_L4,
     .FLCFIGURE_L5,
     .FLCFIGURE_L6,
     .FLCFIGURE_L7,
     .FLCFIGURE_L8,
     .FLCFIGURE_L9,
     .FLCFIGURE_L10,
     .FLCFIGURE_L11,
     .FLCFIGURE_L12,
     .FLCFIGURE_L13,
     .FLCFIGURE_L14,
     .FLCFIGURE_L15,
     .ICCFIGURE_L1,
     .ICCFIGURE_L2,
     .ICCFIGURE_L3,
     .ICCFIGURE_L4,
     .ICCFIGURE_L5,
     .ICCFIGURE_L6,
     .ICCFIGURE_L7,
     .ICCFIGURE_L8,
     .ICCFIGURE_L9,
     .ICCFIGURE_L10,
     .ICCFIGURE_L11,
     .ICCFIGURE_L12,
     .ICCFIGURE_L13,
     .ICCFIGURE_L14,
     .ICCFIGURE_L15,
     .ICCTABLE_L1,
     .ICCTABLE_L2,
     .ICCTABLE_L3,
     .ICCTABLE_L4,
     .ICCTABLE_L5,
     .ICCTABLE_L6,
     .ICCTABLE_L7,
     .ICCTABLE_L8,
     .ICCTABLE_L9,
     .ICCTABLE_L10,
     .ICCTABLE_L11,
     .ICCTABLE_L12,
     .ICCTABLE_L13,
     .ICCTABLE_L14,
     .ICCTABLE_L15 
     .table_div table{
        overflow-x: auto;
    }
}
