
.cover {
    max-width: 40px;
}
td.center {
    width: auto;
}
section {
    position: relative;
    clear: both;
    p {
        margin-right: 10px;
    }
}
.no_highlight {
    background: white !important;
}
.highlighter:hover {
    cursor: pointer;
}
p:hover,
h1:hover {
    cursor: text;
}
::selection {
    background: #ffff00;
}
::-moz-selection {
    background: #ffff00;
}
.v-expansion-panel {
    list-style-type: none !important;
    .v-expansion-panel-content__wrap {
        padding: 0;
    }
}
.chapter-expand {
    max-height: 400px;
    overflow-y: scroll;
}
.note-list-quote {
    user-select:none;
}
.v-stepper__label {
    display: inline!important;
}
.v-stepper__step {
    width: 33%;
}
#contentParent {
    overflow-x: hidden;
    .section-action-wrapper {
        .v-icon {
            color: unset!important;
        }
    }
}
