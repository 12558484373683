.purchasedViewStyles {
    .section-action-wrapper.v-card {
        border: 1px solid #ffffff;
    }
    .section-action-wrapper.v-card--flat:hover {
        border: 1px solid #0B5940;
        border-radius: 5px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(37,87,66,.6);
    }
    .change_ICC {
        color: red !important;
        .changed_ICC_table {
            table {
                border-color: red !important;
                td {
                    color: red !important;
                }
            }
        }
    }
    .content_left {
        float: left;
        text-align: left;
        width: 100%;
        margin-bottom: 10px;
    }
    .continued_definition {
        text-indent: 25px;
    }
    .outside_border_content {
        border:solid 1px; padding:0.2em;
    }
    .deletion_marker_nonICC_para {
        content:">";
        font-size: 18px;
        width: 0%;
        margin-top: -18px;
        color:black;
    }
    .deletion_marker_paragraph {
        font-size: medium;
        background-size: 2% auto;
        height: 18px;
        background-repeat: no-repeat;
        margin-left: -40px;
        margin-top: 10px;
    }
    .deletion_marker {
        display: inline-block;
        font-size: medium;
        background-repeat: no-repeat;
        background-size: 50% auto;
        height: 30px;
        width: 50px;
    }
    a[data-changed="changed_level0"] .relocated_to::before {
        content: "*Relocated To New Location ";
        color: blue !important;
    }
    a[data-changed="changed_level0"] .new_location::before {
        content: "**Relocated From Other Location ";
        color: blue !important;
    }
    a[data-changed="changed_level1"] .relocated_to::before {
        content: "*Relocated To New Location ";
        color: red !important;
    }
    a[data-changed="changed_level1"] .new_location::before {
        content: "**Relocated From Other Location ";
        color: red !important;
    }
    .relocated_to,
    .new_location {
        clear: left;
        color: blue !important;
        margin: 0 0 0 10px;
        :hover {
            color: inherit;
        }
    }
    .relocated_to::before {
        content: "*Relocated To New Location ";
    }
    .new_location::before {
        content: "**Relocated From Other Location ";
    }    
}
body #app .newIccStyles p.deletion_marker_nonICC_para:before {
    margin-left: -14px;
}

body #app .newIccStyles li p.deletion_marker_nonICC_para:before {
    margin-left: -53px;
}
