
.video-container {
    position: relative;
    width: 100%;
    height: 95%;
    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.tag-video-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 0.5em;
}
