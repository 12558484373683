

#tableHeadingContainer {

  @media (min-width: 1264px) {

    position: sticky;
    top: 65px;
    padding: 0 !important;
    background: white;
    z-index: 2;
  }
}

