
.no-margin {
    table {
        margin: 0;
    }
}
.tab-class{
    padding: 0px 15px;
    background: #fff;
    color: #0b5940 !important;
    border: 1px solid #0b5940;
    border-right: 0;
}
.v-tab--active {
    background: #0b5940 !important;
    color: #fff !important;
}
.v-tour > * {
    z-index: 1;
}
